/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import {
    Button,
    FormGroup,
    FormControl,
    InputLabel,
    Link as MaterialLink,
    CircularProgress,
    Box,
    TextField,
    Avatar,
    Checkbox,
    LinearProgress,
    Dialog,
    DialogTitle,
    IconButton,
    Slide,
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
// import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import { FormErrors } from "components/Login/FormErrors";
import { apiPath } from "api";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { userService } from "_services/user.service";
import PhoneNumber from "awesome-phonenumber";
import { authHeader } from "__helpers/auth-header";
import enMsg from "__helpers/locale/en/en";
import {
    NotificationOptions,
    genderType,
    EMAIL_REGEX,
    ALLOWED_PROFILE_IMAGE_SIZE,
    ZIPCODE_REGEX,
    ZIP_CODE_LENGTH,
    MIN_AGE_YEARS,
    ALLOWED_IMAGE_EXTENTION,
    DEFAULT_PROFILE_IMG,
    DIGIT_ONLY,
    PHONE_NO_INVALID_LENGTH,
    OK_SUCCESS_STATUS,
    STATUS_CREATED,
    INVALID_DATA_POST,
    SECURITY_ERROR
} from "__helpers/constants";
import { authHeaderMIMETYPE } from "__helpers/auth-header";
import baseRoutes from "base-routes";
import fileExtension from "file-extension";
import { generalAuthTokenHeader } from "__helpers/auth-header";

import { withRouter } from 'react-router'

import { connect } from "react-redux";
import {
    addUSerUInfo,
    reduxLoad,
} from "js/actions";
import { USER_INFO } from "__helpers/constants";
import { dpBaseRoutes } from "base-routes";
import { basePath } from "base-routes";
import backArrow from "assets/img/icons/arrow-back.svg";
import commingSoonImg from "assets/img/comming_soon.png";
import SwitchToggle from "components/SwitchToggle/SwitchToggle";
import { MAX_INPUT_LENGTH_RATE } from "__helpers/constants";
import { enGB } from "date-fns/esm/locale";
import { Scrollbars } from 'react-custom-scrollbars';
import { providerBaseRoutes } from "base-routes";
function mapDispatchToProps(dispatch) {
    return {
        addUSerUInfo: addUSerUInfoVal => dispatch(addUSerUInfo(addUSerUInfoVal)),
        addTimeStamp: addTimeStampVal => dispatch(addTimeStamp(addTimeStampVal)),
        reduxLoad: reduxLoadVal => dispatch(reduxLoad(reduxLoadVal)),
    };
}
const mapStateToProps = state => {
    return {
        userInfo: state.userInfo,
        categoryListRedux: state.categoryList,
        insuranceListRedux: state.insuranceList,
        patientStatusListRedux: state.patientStatusList,
        hospitalListRedux: state.hospitalList,
        timestamp: state.timestamp,
        reduxLoadFlag: state.reduxLoadFlag,
    };
};
const styles = theme => ({
});
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
});
class ProfileServiceClass extends React.Component {
    inputOpenFileRef = React.createRef(null);
    maxDate = new Date();
    constructor(props) {
        super(props);
        /* create Ref for file input  */
        let categoryParents = {};
        if (typeof props.categoryListRedux === "object" && props.categoryListRedux.length) {
            props.categoryListRedux.map((cList, key) => {
                const { catagoryId, parent } = cList;
                categoryParents[catagoryId] = parent;
            })
        }
        this.state = {
            loading: false,
            userInfo: props.userInfo,
            selectedCategories: 0,
            categoryList: props.categoryListRedux,
            reduxLoadFlag: false,
            selectedCategoriesArr: [],
            selectedCheckBoxArr: [],
            selectedMainCatArr: [],
            categoryParents: categoryParents,
            mainCatArr: [1, 2, 3, 4, 5],
            formErrors: {
                "33": enMsg.categoryServiceReq,
                "34": enMsg.categoryServiceReq,
                "32": enMsg.categoryServiceReq,
                "39": enMsg.categoryServiceReq,
                "40": enMsg.categoryServiceReq,
                "41": enMsg.categoryServiceReq,
                "44": enMsg.categoryServiceReq,
            },
            firstNameValid: false,
            lastNameValid: false,
            middleNameValid: true,
            "33_valid": true,
            "34_valid": true,
            "32_valid": true,
            "39_valid": true,
            "40_valid": true,
            "41_valid": true,
            "44_valid": true,
            homeCareStatus: false,
            nonHomeCareList: [47, 48, 49, 50],
            profileCategory: false,
            selectedProviderProfileId: null,
        };

        this.fatchFn = this.fatchFn.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.handleChangeInput = this.handleChangeInput.bind(this);
        this.handleChangeBox = this.handleChangeBox.bind(this);
        this.handleToggleBox = this.handleToggleBox.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.stopLoading = this.stopLoading.bind(this);
        this.modalClose = this.modalClose.bind(this);
    }
    componentDidMount() {
        // this.setState({
        //     loading: true
        // }, () => this.fatchFn());
    }
    async fatchFn() {
        this.setState({
            loading: true
        })
        let providerCategory = await userService.fetchGlobalApis(apiPath.getProviderProfileCategory + "/" + this.state.selectedProviderProfileId);
        let getProviderFacility = await userService.fetchGlobalApis(apiPath.getProviders + "/" + this.state.selectedProviderProfileId);
        let providerCategoryList = await userService.fetchGlobalApis(apiPath.getProviderSubscription + "/" + this.state.selectedProviderProfileId);
        console.log(providerCategory, getProviderFacility, providerCategoryList, apiPath.providerCategoryUpdate)
        if (providerCategory && providerCategory.length) {
            this.fetchData(providerCategory)
        } else {
            this.setState({
                loading: false,
            })
        }
        if (getProviderFacility) {
            this.setState({
                homeCareStatus: getProviderFacility.homeCare
            })
        }
        if (providerCategoryList) {
            let selectedMainCatArr = [];
            typeof providerCategoryList === "object" && providerCategoryList.length
                ?
                providerCategoryList.map((cList, key) => {
                    if (cList.catStatus) {
                        selectedMainCatArr.push(cList.categoryId)
                    }
                    this.setState({
                        [cList.categoryId]: +cList.value,
                    })
                })
                :
                null
            this.setState({
                loading: false,
                selectedMainCatArr: selectedMainCatArr,
            })
        }

    }
    fetchData(providerCategory) {
        try {
            let selectedCategoriesArr = [];
            let selectedCheckBoxArr = [];
            let selectedMainCatArr = [];
            let loopFinish = false;
            {
                typeof providerCategory === "object" && providerCategory.length
                    ?
                    providerCategory.map((cList, key) => {
                        (this.state.mainCatArr.includes(cList.categoryId))
                            ?
                            selectedMainCatArr.push(cList.categoryId)
                            :
                            (
                                selectedCategoriesArr.push(cList.categoryId),
                                selectedCheckBoxArr.push(cList.categoryId)
                            )
                        this.setState({
                            [cList.categoryId]: +cList.value,
                        })
                        if (key == providerCategory.length - 1) {
                            loopFinish = true
                        }
                    })
                    :
                    null

                if (loopFinish) {
                    setTimeout(function () {
                        this.setState({
                            loading: false,
                            selectedCategoriesArr: selectedCategoriesArr,

                        }, () => this.stopLoading(selectedCheckBoxArr))
                    }.bind(this), 1000);
                }
            }
        } catch (error) {
        }
    }
    stopLoading(selectedCheckBoxArr) {
        setTimeout(function () {
            this.setState({
                selectedCheckBoxArr: selectedCheckBoxArr,
            })
        }.bind(this), 100);
    }
    componentDidUpdate() {
        if (this.props.reduxLoadFlag != undefined && this.state.reduxLoadFlag != this.props.reduxLoadFlag) {
            let categoryList = [];
            let userInfo = {};
            let categoryParents = {};
            if (this.props.categoryListRedux) {
                let selectedcategoryList = this.props.categoryListRedux;
                categoryList = (selectedcategoryList) ? selectedcategoryList : [];
                if (typeof categoryList === "object" && categoryList.length) {
                    categoryList.map((cList, key) => {
                        const { catagoryId, parent } = cList;
                        categoryParents[catagoryId] = parent;
                    })
                }
            }
            if (this.props.userInfo) {
                userInfo = this.props.userInfo;
            }
            this.setState({
                reduxLoadFlag: this.props.reduxLoadFlag,
                categoryList: categoryList,
                userInfo: userInfo,
                categoryParents: categoryParents,
            })
        }
    }
    componentWillReceiveProps(nextProps) {
        if (this.state.profileCategory != nextProps.profileCategory && nextProps.profileCategory) {
            this.setState({
                profileCategory: nextProps.profileCategory,
                selectedProviderProfileId: nextProps.selectedProviderProfileId,
            }, () => this.fatchFn())
        } else {
            this.setState({
                profileCategory: nextProps.profileCategory,
            })
        }
    }
    getPercentageCategoryVales(percentageVal, valueArr) {
        var max_of_array = Math.max.apply(Math, valueArr);
        let minBarValue = 0;
        if (max_of_array <= 100) {
            dataArr.value = valueArr;
            dataArr.percentVal = minBarValue;
            return dataArr;
        }
        var percent = (max_of_array * percentageVal) / 100;
        let localArr = [];
        localArr = valueArr.map((arrVal, key) => {
            if (arrVal < 0) {
                arrVal = arrVal * percent;
                minBarValue = (minBarValue < arrVal) ? minBarValue : arrVal;
            }
            return arrVal;
        });
        let dataArr = [];
        dataArr.value = localArr;
        dataArr.percentVal = minBarValue;
        return dataArr;
    }
    validateField = (fieldName, fieldValue) => {
        let fieldValidationErrors = this.state.formErrors;
        let facilityNameValid = this.state.facilityNameValid;
        let addressValid = this.state.addressValid;

        switch (fieldName) {
            case 'firstName':
                firstNameValid = fieldValue.trim().length > 0;
                fieldValidationErrors.firstName = firstNameValid ? '' : enMsg.firstNameRequiredMsg;
                break;
            case 'middleName':
                middleNameValid = (fieldValue && fieldValue.trim().length > 0) || fieldValue == "" ? true : false;
                fieldValidationErrors.middleName = middleNameValid ? '' : enMsg.middleNameRequiredMsg;
                break;
            default:
                break;
        }
        this.setState({
            formErrors: fieldValidationErrors,
        }, this.validateForm);
    }
    handleSubmit(event) {
        event.preventDefault();
        let childCatArr = [...new Set([...this.state.selectedCategoriesArr, ...this.state.selectedCheckBoxArr])];
        let data = [];
        this.state.selectedMainCatArr.map((mainCat, key) => {
            console.log(mainCat)
            let localObj = {};
            localObj.categoryId = mainCat;
            localObj.parent = this.state.categoryParents[mainCat];
            localObj.parentCatagoryStatus = true;
            localObj.providerId = this.state.selectedProviderProfileId;
            localObj.value = "";
            data.push(localObj)
        })
        childCatArr.map((childCat, key) => {
            console.log(childCat)
            let localObj = {};
            localObj.categoryId = childCat;
            localObj.parent = this.state.categoryParents[childCat];
            localObj.parentCatagoryStatus = false;
            localObj.providerId = this.state.selectedProviderProfileId;
            localObj.value = this.state[childCat] ? this.state[childCat] : "";
            data.push(localObj)
        })
        let showNotification = {};
        // return
        this.setState({
            loading: true
        });
        const response = fetch(apiPath.saveProviderProfileCategory + "/" + this.state.selectedProviderProfileId, {
            method: "PUT",
            headers: generalAuthTokenHeader(),
            body: JSON.stringify(data),
            data: JSON.stringify(data)
        })
            .then(response => {
                if (response.status === 400) {
                    showNotification = {
                        title: enMsg.categoryFailedTitle,
                        message: enMsg.categoryFailedDes,
                        type: "danger"
                    };
                } else if (response.ok) {
                    this.modalClose()
                } else {
                    showNotification = {
                        title: enMsg.categoryFailedTitle,
                        message: enMsg.categoryFailedDes,
                        type: "danger"
                    };
                }
                userService.showNotification(showNotification);
                this.setState({ loading: false });
                return response.json();
            })
            .then(data => {
                if (data.length) {
                    showNotification = {
                        title: "Success",
                        message: enMsg.categorySuccessDes,
                        type: "success"
                    };
                    userService.showNotification(showNotification);
                }
                return true;
            })
            .catch(error => {
                showNotification = {
                    title: enMsg.categoryFailedTitle,
                    message: 'Something went wrong.Please try after sometime..',
                    type: "danger"
                };
                userService.showNotification(showNotification);
                this.setState({ loading: false });
            });
    }
    handleChangeInput = (event, parentId) => {
        const name = +event.target.name;
        let value = event.target.value;
        const re = /^\d+\.?\d{0,2}$/;
        if (value === '' || re.test(value)) {
            value = value
        } else {
            if (re.test(this.state[name]))
                value = this.state[name];
            else
                value = 0;
        }
        let selectedCategoriesArr = this.state.selectedCategoriesArr;
        if (!selectedCategoriesArr.includes(name))
            selectedCategoriesArr.push(name)
        this.setState({
            [name]: value,
            selectedCategoriesArr: selectedCategoriesArr
        });
    }
    handleChangeBox = (catagoryId, parentId) => {
        const name = catagoryId;
        let value = catagoryId;

        let selectedCategoriesArr = this.state.selectedCategoriesArr;
        let selectedCheckBoxArr = this.state.selectedCheckBoxArr;
        if (!selectedCategoriesArr.includes(name)) {
            selectedCategoriesArr.push(name)
        } else {
            const index = selectedCategoriesArr.indexOf(name);
            if (index > -1) {
                selectedCategoriesArr.splice(index, 1);
            }
        }
        if (selectedCheckBoxArr.includes(name)) {
            const index = selectedCheckBoxArr.indexOf(name);
            if (index > -1) {
                selectedCheckBoxArr.splice(index, 1);
            }
        } else {
            selectedCheckBoxArr.push(name)
        }
        this.setState({
            // [name]: 0,
            selectedCategoriesArr: selectedCategoriesArr,
            selectedCheckBoxArr: selectedCheckBoxArr,
        });
    }
    handleToggleBox = (catagoryId, parentId) => {
        const name = catagoryId;
        let value = catagoryId;

        let selectedMainCatArr = this.state.selectedMainCatArr;
        if (!selectedMainCatArr.includes(name)) {
            selectedMainCatArr.push(name)
        } else {
            const index = selectedMainCatArr.indexOf(name);
            if (index > -1) {
                selectedMainCatArr.splice(index, 1);
            }
        }
        this.setState({
            selectedMainCatArr: selectedMainCatArr,
        });
    }
    modalClose = () => {
        this.setState({
            selectedCategoriesArr: [],
            selectedCheckBoxArr: [],
            selectedMainCatArr: [],
        }, () => this.props.providerProfileCategory())
    };
    render() {
        const {
            categoryList
        } = this.state;
        return (
            <Dialog
                open={this.state.profileCategory}
                onClose={this.modalClose}
                aria-labelledby="provider-dialog-title"
                className="add-modal editproviderDialog ManageProvideCategories"
                TransitionComponent={Transition}
            >
                <DialogTitle className="add-modal-title manage_title" id="provider-dialog-title ">
                    {"Manage Provider Categories"}
                    <IconButton className="closeButton" aria-label="close" onClick={this.modalClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <div className="ProviderProfile">
                    {this.state.loading
                        ?
                        <LinearProgress
                            color="primary"
                        />
                        :
                        ""
                    }
                    <div className="tab-heading service_cat">Service Categories</div>
                    <p className="tab-sub-heading" >Select categories for Active Service Categories <span>({this.state.selectedMainCatArr.length} Selected)</span> </p>
                    {
                        this.state.selectedMainCatArr && this.state.selectedMainCatArr.length
                            ?
                            <form onSubmit={this.handleSubmit} noValidate>
                                <Box display="flex" flexDirection="row" width={1} mt={2} className={"all-cover"} >
                                    {
                                        (this.state.selectedMainCatArr.includes(4))
                                            ?
                                            <Box width={1 / 4} className={"setcat HomeCare-cover"} display="flex" flexDirection="column">

                                                <Box display="flex" flexDirection="row" width={1} className="caption-switch" >
                                                    <div className="caption">Home Care</div>

                                                </Box>

                                                <Scrollbars
                                                    style={{ height: "calc(100vh - 333px)" }}
                                                    autoHide={false}
                                                    universal={true}
                                                    className="serviceScroll"
                                                >
                                                    <Box width={1} display="flex" flexDirection="column" justifyContent="flex-start" className="form-box" pr={2} mt={2} key={"homeCare"} >


                                                        {typeof categoryList === "object" && categoryList.length
                                                            ? categoryList.map((cList, key) => {
                                                                if (cList.catagoryId == 4) {
                                                                    const { children } = cList;
                                                                    return children.map((mChildren, mKey) => {
                                                                        return (
                                                                            (mChildren.catagoryId == 31)
                                                                                ?
                                                                                <div className="checkbox-input-cover" key={mKey}>
                                                                                    <Checkbox
                                                                                        disabled={this.state.selectedMainCatArr.includes(4) ? false : true}
                                                                                        checked={this.state.selectedCheckBoxArr.includes(mChildren.catagoryId) ? true : false}
                                                                                        onChange={(event) => this.handleChangeBox(mChildren.catagoryId, mChildren.parent)}
                                                                                        key={mKey}
                                                                                    />
                                                                                    <span className="rate-text">{(mChildren.categoryValue) ? mChildren.categoryValue : "Unknown"}</span>
                                                                                    {
                                                                                        mChildren.children.map((cChildren, cKey) => {
                                                                                            return (
                                                                                                <div className="input-box" key={cKey}>
                                                                                                    <div className="input-text">{(cChildren.categoryValue) ? cChildren.categoryValue : "Unknown"}</div>
                                                                                                    <Box display="flex" flexDirection="row" justifyContent="center" width={1} className="input-unit" >
                                                                                                        <div>
                                                                                                            <TextField
                                                                                                                name={"" + cChildren.catagoryId}
                                                                                                                variant="outlined"
                                                                                                                size="small"
                                                                                                                value={this.state[cChildren.catagoryId] || ""}
                                                                                                                label="Typical Rate"
                                                                                                                onChange={(event) => this.handleChangeInput(event, cChildren.parent)}
                                                                                                                inputProps={{ maxLength: MAX_INPUT_LENGTH_RATE, pattern: "^(\d|10)\.\d{2}$" }}
                                                                                                                pattern='[0-9]{0,5}'
                                                                                                                disabled={this.state.selectedCheckBoxArr.includes(mChildren.catagoryId) ? false : true}
                                                                                                            />
                                                                                                            <FormErrors
                                                                                                                show={!this.state[cChildren.catagoryId + "_valid"]}
                                                                                                                formErrors={this.state.formErrors}
                                                                                                                fieldName={cChildren.catagoryId}
                                                                                                            />
                                                                                                        </div>
                                                                                                        <span className="unit">($/hr)</span>
                                                                                                    </Box>

                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                                :
                                                                                <div className="checkbox-input-cover" key={mKey}>
                                                                                    <Checkbox
                                                                                        disabled={this.state.selectedMainCatArr.includes(4) ? false : true}
                                                                                        checked={this.state.selectedCheckBoxArr.includes(mChildren.catagoryId) ? true : false}
                                                                                        onChange={(event) => this.handleChangeBox(mChildren.catagoryId, mChildren.parent)}
                                                                                        key={mKey}
                                                                                    />
                                                                                    <span className="rate-text">{(mChildren.categoryValue) ? mChildren.categoryValue : "Unknown"}</span>

                                                                                    <div className="input-box" >
                                                                                        <Box display="flex" flexDirection="row" justifyContent="center" width={1} className="input-unit" >
                                                                                            <div>
                                                                                                <TextField
                                                                                                    name={"" + mChildren.catagoryId}
                                                                                                    variant="outlined"
                                                                                                    size="small"
                                                                                                    value={this.state[mChildren.catagoryId] || ""}
                                                                                                    label="Typical Rate"
                                                                                                    onChange={(event) => this.handleChangeInput(event, mChildren.parent)}
                                                                                                    inputProps={{ maxLength: MAX_INPUT_LENGTH_RATE, pattern: "^(\d|10)\.\d{2}$" }}
                                                                                                    disabled={this.state.selectedCheckBoxArr.includes(mChildren.catagoryId) ? false : true}
                                                                                                />
                                                                                                <FormErrors
                                                                                                    show={!this.state[mChildren.catagoryId + "_valid"]}
                                                                                                    formErrors={this.state.formErrors}
                                                                                                    fieldName={mChildren.catagoryId}
                                                                                                />
                                                                                            </div>
                                                                                            <span className="unit">($/hr)</span>
                                                                                        </Box>
                                                                                    </div>

                                                                                </div>
                                                                        );
                                                                    })
                                                                }
                                                            })
                                                            : "no category.."}
                                                    </Box>
                                                </Scrollbars>
                                            </Box>
                                            :
                                            null
                                    }
                                    {
                                        (this.state.selectedMainCatArr.includes(2))
                                            ?
                                            <Box width={1 / 4} className={"setcat Hospice-cover"} display="flex" flexDirection="column">

                                                <Box display="flex" flexDirection="row" width={1} className="caption-switch" >
                                                    <div className="caption">Hospice</div>
                                                </Box>

                                                <Scrollbars
                                                    style={{ height: "calc(100vh - 333px)" }}
                                                    autoHide={false}
                                                    universal={true}
                                                    className="serviceScroll"
                                                >
                                                    <Box width={1} display="flex" flexDirection="column" justifyContent="flex-start" className="form-box" pr={2} mt={2} key={"hospice"}>

                                                        {typeof categoryList === "object" && categoryList.length
                                                            ? categoryList.map((cList, key) => {
                                                                if (cList.catagoryId == 2) {
                                                                    const { children } = cList;
                                                                    return children.map((mChildren, mKey) => {
                                                                        return (

                                                                            <Box key={mKey}>
                                                                                <div className="checkbox-input-cover">
                                                                                    <Checkbox
                                                                                        disabled={this.state.selectedMainCatArr.includes(2) ? false : true}
                                                                                        checked={this.state.selectedCheckBoxArr.includes(mChildren.catagoryId) ? true : false}
                                                                                        onChange={(event) => this.handleChangeBox(mChildren.catagoryId, mChildren.parent)}
                                                                                        key={mKey}
                                                                                    />
                                                                                    <span className="rate-text">
                                                                                        {(mChildren.categoryValue) ? mChildren.categoryValue : "Unknown"}
                                                                                    </span>
                                                                                </div>
                                                                            </Box>
                                                                        );
                                                                    })
                                                                }
                                                            })
                                                            : "no category.."}

                                                    </Box>
                                                </Scrollbars>
                                            </Box>
                                            :
                                            null
                                    }
                                    {
                                        (this.state.selectedMainCatArr.includes(5))
                                            ?
                                            <Box width={1 / 4} className={"setcat Placement-cover"} display="flex" flexDirection="column">
                                                <Box display="flex" flexDirection="row" width={1} className="caption-switch" >
                                                    <div className="caption">Placement</div>
                                                </Box>

                                                <Scrollbars
                                                    style={{ height: "calc(100vh - 333px)" }}
                                                    autoHide={false}
                                                    universal={true}
                                                    className="serviceScroll"
                                                >
                                                    <Box width={1} display="flex" flexDirection="column" justifyContent="flex-start" className="form-box" pr={2} mt={2} key={"placement"}>
                                                        {typeof categoryList === "object" && categoryList.length
                                                            ? categoryList.map((cList, key) => {
                                                                if (cList.catagoryId == 5) {
                                                                    const { children } = cList;
                                                                    return children.map((mChildren, mKey) => {
                                                                        if (this.state.homeCareStatus) {
                                                                            if (!this.state.nonHomeCareList.includes(mChildren.catagoryId)) {
                                                                                return (
                                                                                    (mChildren.catagoryId == 35)
                                                                                        ?
                                                                                        <Box key={mKey}>
                                                                                            <div className="checkbox-input-cover">
                                                                                                <Checkbox
                                                                                                    disabled={this.state.selectedMainCatArr.includes(5) ? false : true}
                                                                                                    checked={this.state.selectedCheckBoxArr.includes(mChildren.catagoryId) ? true : false}
                                                                                                    onChange={(event) => this.handleChangeBox(mChildren.catagoryId, mChildren.parent)}
                                                                                                    key={mKey}
                                                                                                />
                                                                                                <span className="rate-text">
                                                                                                    {(mChildren.categoryValue) ? mChildren.categoryValue : "Unknown"}
                                                                                                </span>
                                                                                            </div>
                                                                                            {
                                                                                                mChildren.children.map((cChildren, cKey) => {
                                                                                                    return (
                                                                                                        <div className="input-box" key={cKey}>
                                                                                                            <div className="checkbox-input-cover">
                                                                                                                <Checkbox
                                                                                                                    disabled={this.state.selectedMainCatArr.includes(5) && this.state.selectedCheckBoxArr.includes(mChildren.catagoryId) ? false : true}
                                                                                                                    checked={this.state.selectedCheckBoxArr.includes(cChildren.catagoryId) ? true : false}
                                                                                                                    onChange={(event) => this.handleChangeBox(cChildren.catagoryId, cChildren.parent)}
                                                                                                                    key={cKey}
                                                                                                                />
                                                                                                                <span className="rate-text">
                                                                                                                    {(cChildren.categoryValue) ? cChildren.categoryValue : "Unknown"}
                                                                                                                </span>
                                                                                                            </div>
                                                                                                            <Box display="flex" flexDirection="row" justifyContent="center" width={1} className="input-unit" >
                                                                                                                <div>
                                                                                                                    <TextField
                                                                                                                        name={"" + cChildren.catagoryId}
                                                                                                                        variant="outlined"
                                                                                                                        size="small"
                                                                                                                        value={this.state[cChildren.catagoryId] || ""}
                                                                                                                        label="Rate"
                                                                                                                        onChange={(event) => this.handleChangeInput(event, cChildren.parent)}
                                                                                                                        inputProps={{ maxLength: MAX_INPUT_LENGTH_RATE, pattern: "^(\d|10)\.\d{2}$" }}
                                                                                                                        disabled={this.state.selectedMainCatArr.includes(5) && this.state.selectedCheckBoxArr.includes(cChildren.catagoryId) && this.state.selectedCheckBoxArr.includes(mChildren.catagoryId) ? false : true}
                                                                                                                    />
                                                                                                                    <FormErrors
                                                                                                                        show={!this.state[cChildren.catagoryId + "_valid"]}
                                                                                                                        formErrors={this.state.formErrors}
                                                                                                                        fieldName={cChildren.catagoryId}
                                                                                                                    />
                                                                                                                </div>
                                                                                                                <span className="unit">($/month)</span>
                                                                                                            </Box>

                                                                                                        </div>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </Box>
                                                                                        :
                                                                                        (mChildren.catagoryId == 36)
                                                                                            ?
                                                                                            <Box key={mKey}>
                                                                                                <div className="checkbox-input-cover">
                                                                                                    <Checkbox
                                                                                                        disabled={this.state.selectedMainCatArr.includes(5) ? false : true}
                                                                                                        checked={this.state.selectedCheckBoxArr.includes(mChildren.catagoryId) ? true : false}
                                                                                                        onChange={(event) => this.handleChangeBox(mChildren.catagoryId, mChildren.parent)}
                                                                                                        key={mKey}
                                                                                                    />
                                                                                                    <span className="rate-text">
                                                                                                        {(mChildren.categoryValue) ? mChildren.categoryValue : "Unknown"}
                                                                                                    </span>
                                                                                                </div>
                                                                                                {
                                                                                                    mChildren.children.map((cChildren, cKey) => {
                                                                                                        return (

                                                                                                            <div className="input-box" key={cKey}>
                                                                                                                <div className="checkbox-input-cover">
                                                                                                                    <Checkbox
                                                                                                                        disabled={this.state.selectedMainCatArr.includes(5) && this.state.selectedCheckBoxArr.includes(mChildren.catagoryId) ? false : true}
                                                                                                                        checked={this.state.selectedCheckBoxArr.includes(cChildren.catagoryId) ? true : false}
                                                                                                                        onChange={(event) => this.handleChangeBox(cChildren.catagoryId, cChildren.parent)}
                                                                                                                        key={cKey}
                                                                                                                    />
                                                                                                                    <span className="rate-text">
                                                                                                                        {(cChildren.categoryValue) ? cChildren.categoryValue : "Unknown"}
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                                <Box display="flex" flexDirection="row" justifyContent="center" width={1} className="input-unit" >
                                                                                                                    <div>
                                                                                                                        <TextField
                                                                                                                            name={"" + cChildren.catagoryId}
                                                                                                                            variant="outlined"
                                                                                                                            size="small"
                                                                                                                            value={this.state[cChildren.catagoryId] || ""}
                                                                                                                            label="Rate"
                                                                                                                            onChange={(event) => this.handleChangeInput(event, cChildren.parent)}
                                                                                                                            inputProps={{ maxLength: MAX_INPUT_LENGTH_RATE, pattern: "^(\d|10)\.\d{2}$" }}
                                                                                                                            disabled={this.state.selectedMainCatArr.includes(5) && this.state.selectedCheckBoxArr.includes(cChildren.catagoryId) && this.state.selectedCheckBoxArr.includes(mChildren.catagoryId) ? false : true}
                                                                                                                        />
                                                                                                                        <FormErrors
                                                                                                                            show={!this.state[cChildren.catagoryId + "_valid"]}
                                                                                                                            formErrors={this.state.formErrors}
                                                                                                                            fieldName={cChildren.catagoryId}
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                    <span className="unit">($/month)</span>
                                                                                                                </Box>

                                                                                                            </div>
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                            </Box>
                                                                                            :
                                                                                            (mChildren.catagoryId == 51)
                                                                                                ?
                                                                                                <Box key={mKey}>
                                                                                                    <div className="checkbox-input-cover">
                                                                                                        <Checkbox
                                                                                                            disabled={this.state.selectedMainCatArr.includes(5) ? false : true}
                                                                                                            checked={true}
                                                                                                            key={mKey}
                                                                                                        />
                                                                                                        <span className="rate-text">
                                                                                                            {(mChildren.categoryValue) ? mChildren.categoryValue : "Unknown"}
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </Box>
                                                                                                :
                                                                                                <Box key={mKey}>
                                                                                                    <div className="checkbox-input-cover">
                                                                                                        <Checkbox
                                                                                                            disabled={this.state.selectedMainCatArr.includes(5) ? false : true}
                                                                                                            checked={this.state.selectedCheckBoxArr.includes(mChildren.catagoryId) ? true : false}
                                                                                                            onChange={(event) => this.handleChangeBox(mChildren.catagoryId, mChildren.parent)}
                                                                                                            key={mKey}
                                                                                                        />
                                                                                                        <span className="rate-text">
                                                                                                            {(mChildren.categoryValue) ? mChildren.categoryValue : "Unknown"}
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </Box>
                                                                                );
                                                                            }
                                                                        } else {
                                                                            if (mChildren.catagoryId != 51) {
                                                                                return (
                                                                                    (mChildren.catagoryId == 35)
                                                                                        ?
                                                                                        <Box key={mKey}>
                                                                                            <div className="checkbox-input-cover">
                                                                                                <Checkbox
                                                                                                    disabled={this.state.selectedMainCatArr.includes(5) ? false : true}
                                                                                                    checked={this.state.selectedCheckBoxArr.includes(mChildren.catagoryId) ? true : false}
                                                                                                    onChange={(event) => this.handleChangeBox(mChildren.catagoryId, mChildren.parent)}
                                                                                                    key={mKey}
                                                                                                />
                                                                                                <span className="rate-text">
                                                                                                    {(mChildren.categoryValue) ? mChildren.categoryValue : "Unknown"}
                                                                                                </span>
                                                                                            </div>
                                                                                            {
                                                                                                mChildren.children.map((cChildren, cKey) => {
                                                                                                    return (
                                                                                                        <div className="input-box" key={cKey}>
                                                                                                            <div className="checkbox-input-cover">
                                                                                                                <Checkbox
                                                                                                                    disabled={this.state.selectedMainCatArr.includes(5) && this.state.selectedCheckBoxArr.includes(mChildren.catagoryId) ? false : true}
                                                                                                                    checked={this.state.selectedCheckBoxArr.includes(cChildren.catagoryId) ? true : false}
                                                                                                                    onChange={(event) => this.handleChangeBox(cChildren.catagoryId, cChildren.parent)}
                                                                                                                    key={cKey}
                                                                                                                />
                                                                                                                <span className="rate-text">
                                                                                                                    {(cChildren.categoryValue) ? cChildren.categoryValue : "Unknown"}
                                                                                                                </span>
                                                                                                            </div>
                                                                                                            <Box display="flex" flexDirection="row" justifyContent="center" width={1} className="input-unit" >
                                                                                                                <div>
                                                                                                                    <TextField
                                                                                                                        name={"" + cChildren.catagoryId}
                                                                                                                        variant="outlined"
                                                                                                                        size="small"
                                                                                                                        value={this.state[cChildren.catagoryId] || ""}
                                                                                                                        label="Rate"
                                                                                                                        onChange={(event) => this.handleChangeInput(event, cChildren.parent)}
                                                                                                                        inputProps={{ maxLength: MAX_INPUT_LENGTH_RATE, pattern: "^(\d|10)\.\d{2}$" }}
                                                                                                                        disabled={this.state.selectedMainCatArr.includes(5) && this.state.selectedCheckBoxArr.includes(cChildren.catagoryId) && this.state.selectedCheckBoxArr.includes(mChildren.catagoryId) ? false : true}
                                                                                                                    />
                                                                                                                    <FormErrors
                                                                                                                        show={!this.state[cChildren.catagoryId + "_valid"]}
                                                                                                                        formErrors={this.state.formErrors}
                                                                                                                        fieldName={cChildren.catagoryId}
                                                                                                                    />
                                                                                                                </div>
                                                                                                                <span className="unit">($/month)</span>
                                                                                                            </Box>

                                                                                                        </div>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </Box>
                                                                                        :
                                                                                        (mChildren.catagoryId == 36)
                                                                                            ?
                                                                                            <Box key={mKey}>
                                                                                                <div className="checkbox-input-cover">
                                                                                                    <Checkbox
                                                                                                        disabled={this.state.selectedMainCatArr.includes(5) ? false : true}
                                                                                                        checked={this.state.selectedCheckBoxArr.includes(mChildren.catagoryId) ? true : false}
                                                                                                        onChange={(event) => this.handleChangeBox(mChildren.catagoryId, mChildren.parent)}
                                                                                                        key={mKey}
                                                                                                    />
                                                                                                    <span className="rate-text">
                                                                                                        {(mChildren.categoryValue) ? mChildren.categoryValue : "Unknown"}
                                                                                                    </span>
                                                                                                </div>
                                                                                                {
                                                                                                    mChildren.children.map((cChildren, cKey) => {
                                                                                                        return (

                                                                                                            <div className="input-box" key={cKey}>
                                                                                                                <div className="checkbox-input-cover">
                                                                                                                    <Checkbox
                                                                                                                        disabled={this.state.selectedMainCatArr.includes(5) && this.state.selectedCheckBoxArr.includes(mChildren.catagoryId) ? false : true}
                                                                                                                        checked={this.state.selectedCheckBoxArr.includes(cChildren.catagoryId) ? true : false}
                                                                                                                        onChange={(event) => this.handleChangeBox(cChildren.catagoryId, cChildren.parent)}
                                                                                                                        key={cKey}
                                                                                                                    />
                                                                                                                    <span className="rate-text">
                                                                                                                        {(cChildren.categoryValue) ? cChildren.categoryValue : "Unknown"}
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                                <Box display="flex" flexDirection="row" justifyContent="center" width={1} className="input-unit" >
                                                                                                                    <div>
                                                                                                                        <TextField
                                                                                                                            name={"" + cChildren.catagoryId}
                                                                                                                            variant="outlined"
                                                                                                                            size="small"
                                                                                                                            value={this.state[cChildren.catagoryId] || ""}
                                                                                                                            label="Rate"
                                                                                                                            onChange={(event) => this.handleChangeInput(event, cChildren.parent)}
                                                                                                                            inputProps={{ maxLength: MAX_INPUT_LENGTH_RATE, pattern: "^(\d|10)\.\d{2}$" }}
                                                                                                                            disabled={this.state.selectedMainCatArr.includes(5) && this.state.selectedCheckBoxArr.includes(cChildren.catagoryId) && this.state.selectedCheckBoxArr.includes(mChildren.catagoryId) ? false : true}
                                                                                                                        />
                                                                                                                        <FormErrors
                                                                                                                            show={!this.state[cChildren.catagoryId + "_valid"]}
                                                                                                                            formErrors={this.state.formErrors}
                                                                                                                            fieldName={cChildren.catagoryId}
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                    <span className="unit">($/month)</span>
                                                                                                                </Box>

                                                                                                            </div>
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                            </Box>
                                                                                            :
                                                                                            <Box key={mKey}>
                                                                                                <div className="checkbox-input-cover">
                                                                                                    <Checkbox
                                                                                                        disabled={this.state.selectedMainCatArr.includes(5) ? false : true}
                                                                                                        checked={this.state.selectedCheckBoxArr.includes(mChildren.catagoryId) ? true : false}
                                                                                                        onChange={(event) => this.handleChangeBox(mChildren.catagoryId, mChildren.parent)}
                                                                                                        key={mKey}
                                                                                                    />
                                                                                                    <span className="rate-text">
                                                                                                        {(mChildren.categoryValue) ? mChildren.categoryValue : "Unknown"}
                                                                                                    </span>
                                                                                                </div>
                                                                                            </Box>
                                                                                );
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                            })
                                                            : "no category.."}
                                                    </Box>
                                                </Scrollbars>

                                            </Box>
                                            :
                                            null
                                    }
                                    {
                                        (this.state.selectedMainCatArr.includes(1))
                                            ?
                                            <Box width={1 / 4} className={"setcat HomeHealth-cover"} display="flex" flexDirection="column">

                                                <Box display="flex" flexDirection="row" width={1} className="caption-switch" >
                                                    <div className="caption">Home Health</div>

                                                </Box>

                                                <Scrollbars
                                                    style={{ height: "calc(100vh - 333px)" }}
                                                    autoHide={false}
                                                    universal={true}
                                                    className="serviceScroll"
                                                >
                                                    <Box width={1} display="flex" flexDirection="column" justifyContent="flex-start" className="form-box" pr={2} mt={2} key={"homehealth"}>

                                                        {typeof categoryList === "object" && categoryList.length
                                                            ? categoryList.map((cList, key) => {
                                                                if (cList.catagoryId == 1) {
                                                                    const { children } = cList;
                                                                    return children.map((mChildren, mKey) => {
                                                                        return (
                                                                            <Box key={mKey}>
                                                                                <div className="checkbox-input-cover">
                                                                                    <Checkbox
                                                                                        disabled={this.state.selectedMainCatArr.includes(1) ? false : true}
                                                                                        checked={this.state.selectedCheckBoxArr.includes(mChildren.catagoryId) ? true : false}
                                                                                        onChange={(event) => this.handleChangeBox(mChildren.catagoryId, mChildren.parent)}
                                                                                        key={mKey}
                                                                                    />
                                                                                    <span className="rate-text">{(mChildren.categoryValue) ? mChildren.categoryValue : "Unknown"}</span>
                                                                                </div>
                                                                            </Box>
                                                                        );
                                                                    })
                                                                }
                                                            })
                                                            : "no category.."}

                                                    </Box>
                                                </Scrollbars>
                                            </Box>
                                            :
                                            null
                                    }
                                </Box>

                                <Box>
                                    <div className="form-button-cover form-actions">
                                        <Button
                                            className={this.state.loading ? "buttonSuccess btn1" : "btn1"}
                                            disabled={this.state.loading}
                                            type="submit"
                                        >
                                            Save
                                            {this.state.loading && (
                                                <CircularProgress
                                                    size={24}
                                                    className="buttonProgress"
                                                />
                                            )}
                                        </Button>
                                        <Button
                                            className="back-btn btn2"
                                            type="button"
                                            onClick={this.modalClose}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </Box>
                            </form>
                            :
                            this.state.loading
                                ?
                                null
                                :
                                <Box className="nocategory">
                                    No categories added, Please add categories from <b>Subscription Detail</b> popup or <b>Please contact provider</b>
                                </Box>
                    }
                </div>
            </Dialog>
        );
    }
}

ProfileServiceClass.propTypes = {
    classes: PropTypes.object
};
const ServiceCategory = connect(
    mapStateToProps, mapDispatchToProps
)(ProfileServiceClass);
export default withStyles(styles)(ServiceCategory);
