import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import InfiniteScroll from "react-infinite-scroller";
import CloseIcon from "@material-ui/icons/Close";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import { apiPath } from "api";
import { generalAuthTokenHeader } from "__helpers/auth-header";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import { Scrollbars } from "react-custom-scrollbars";
import { findProviderService } from "__helpers/util";

import Tooltip from "@material-ui/core/Tooltip";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import commentIcon from "assets/img/icons/comment.svg";
import {  downloadMedia } from "__helpers/util";

import {
  IconButton,
  Link as MaterialLink,
  LinearProgress,
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  withStyles,
  TextField,
} from "@material-ui/core";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import DateFnsUtils from "@date-io/date-fns";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import notesArrow from "assets/img/icons/notes-arrow.svg";
import { connect } from "react-redux";
import { reduxLoad } from "js/actions/index";
import { userService } from "_services/user.service";
import { REFERRAL_TYPE, REFERRAL_STATUS } from "__helpers/constants";
import PatientCriteria from "views/dpViews/SinglePatientDetail/PatientCriteria";
import PatientNotes from "views/dpViews/SinglePatientDetail/PatientNotes";
import TransportDetails from "./TransportDetails";
import FaxDetails from "./FaxDetails";
import { MAX_INPUT_LENGTH_TEXT_BOX } from "__helpers/constants";

function mapDispatchToProps(dispatch) {
  return {
    reduxLoad: (reduxLoadVal) => dispatch(reduxLoad(reduxLoadVal)),
  };
}
const mapStateToProps = (state) => {
  return {
    userInfoRedux: state.userInfo,
    categoryListRedux: state.categoryList,
    insuranceListRedux: state.insuranceList,
    patientStatusListRedux: state.patientStatusList,
    hospitalListRedux: state.hospitalList,
    timestampRedux: state.timestamp,
    reduxLoadFlag: state.reduxLoadFlag,
  };
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});
const tooltipStyles = {
  tooltip: {
    width: "auto !important",
    // height: "36px",
    borderRadius: "18px",
    boxShadow: "0 20px 80px 0",
  },
};
const CustomTooltip = withStyles(tooltipStyles)(Tooltip);
class AwaitingProviderTabPanelClass extends React.Component {
  constructor(props) {
    super(props);
    this.dateUtility = new DateFnsUtils();
    let totalCategory = {
      1: {
        mainCategory: [],
        subCategory: [],
        childCategory: [],
      },
      2: {
        mainCategory: [],
        subCategory: [],
        childCategory: [],
      },
      3: {
        mainCategory: [],
        subCategory: [],
        childCategory: [],
      },
      4: {
        mainCategory: [],
        subCategory: [],
        childCategory: [],
      },
      5: {
        mainCategory: [],
        subCategory: [],
        childCategory: [],
      },
    };
    if (this.props.categoryListRedux) {
      if (this.props.categoryListRedux && this.props.categoryListRedux.length) {
        this.props.categoryListRedux.map((cList, key) => {
          // main loop
          if (cList.catagoryId == 1) {
            const { children } = cList;
            children.map((mChildren, mKey) => {
              if (mChildren.children && mChildren.children.length) {
                mChildren.children.map((cChildren, cKey) => {
                  totalCategory[1].childCategory.push(cChildren.catagoryId);
                });
              } else {
                totalCategory[1].subCategory.push(mChildren.catagoryId);
              }
            });
          }
          if (cList.catagoryId == 2) {
            const { children } = cList;
            children.map((mChildren, mKey) => {
              if (mChildren.children && mChildren.children.length) {
                mChildren.children.map((cChildren, cKey) => {
                  totalCategory[2].childCategory.push(cChildren.catagoryId);
                });
              } else {
                totalCategory[2].subCategory.push(mChildren.catagoryId);
              }
            });
          }
          if (cList.catagoryId == 3) {
            const { children } = cList;
            children.map((mChildren, mKey) => {
              if (mChildren.children && mChildren.children.length) {
                mChildren.children.map((cChildren, cKey) => {
                  totalCategory[3].childCategory.push(cChildren.catagoryId);
                });
              } else {
                totalCategory[3].subCategory.push(mChildren.catagoryId);
              }
            });
          }
          if (cList.catagoryId == 4) {
            const { children } = cList;
            children.map((mChildren, mKey) => {
              if (mChildren.children && mChildren.children.length) {
                mChildren.children.map((cChildren, cKey) => {
                  totalCategory[4].childCategory.push(cChildren.catagoryId);
                });
              } else {
                totalCategory[4].subCategory.push(mChildren.catagoryId);
              }
            });
          }
          if (cList.catagoryId == 5) {
            const { children } = cList;
            children.map((mChildren, mKey) => {
              if (mChildren.children && mChildren.children.length) {
                mChildren.children.map((cChildren, cKey) => {
                  totalCategory[5].childCategory.push(cChildren.catagoryId);
                });
              } else {
                totalCategory[5].subCategory.push(mChildren.catagoryId);
              }
            });
          }
        });
      }
    }
    let spinner = document.getElementById("loadingSpinner");
    this.state = {
      loading: false,
      isCatAvailable: [],
      refferalProviderEntity: [],
      refferalProviderCommentDP: [],
      providerData: {},
      page: 1,
      searchKeyword: "",
      providerIds: [],
      spinner: spinner,
      size: 10,
      refferalId: this.props.refferalId ? this.props.refferalId : "",
      fetchMore: false,
      categoryId: this.props.categoryId,
      selectedCategoryId: this.props.categoryId,
      patientId: JSON.parse(localStorage.getItem("_selected_patient_list")),
      totalPatients: 0,
      providerStatus: null,
      categoryList: props.categoryListRedux ? props.categoryListRedux : [],
      totalCategoryIds: totalCategory,
      providerIdForStatusChange: "",
      providerReferralStatusId: "",
      newReferralStatusId: "",
      referralStatus: REFERRAL_STATUS,
      confirmBoxStatus: false,
      patientInfo: this.props.patientInfo ? this.props.patientInfo : {},
      patientCriteriaModalStatus: false,
      referralProviderName: "",
      searchKeyword: "",
      openConfirmationBox: false,
      owlCarouselOptions: {
        loop: false,
        dots: false,
        margin: 0,
        nav: true,
        stagePadding: 1,
        items: 6,
        navText: [
          "<i class='fa fa-chevron-left'></i>",
          "<i class='fa fa-chevron-right'></i>",
        ],
        responsiveClass: true,
        responsive: {
          0: {
            items: 1,
          },
          576: {
            items: 3,
          },
          768: {
            items: 5,
          },
          850: {
            items: 3,
          },
          992: {
            items: 2,
          },
          1100: {
            items: 3,
          },
          1200: {
            items: 4,
          },
          1400: {
            items: 5,
          },
          1600: {
            items: 6,
          },
          1800: {
            items: 7,
          },
        },
      },
      patientNotesStatus: false,
      referralPatientModal: false,
      refferalStatus: 2,
      transportDetailsModal: false,
      faxDetailsModal: false,
      statusAnswer: "",
    };
    this.searchProvider = this.searchProvider.bind(this);
    this.searchReturn = this.searchReturn.bind(this);
    this.selectProviderCheckBox = this.selectProviderCheckBox.bind(this);
    this.selectProviderStatus = this.selectProviderStatus.bind(this);
    this.rescindReferral = this.rescindReferral.bind(this);
    this.statusConfirmBox = this.statusConfirmBox.bind(this);
    this.patientCriteriaModalOpen = this.patientCriteriaModalOpen.bind(this);
    this.patientCriteriaModalClose = this.patientCriteriaModalClose.bind(this);
    this.searchProviderText = this.searchProviderText.bind(this);
    this.rescindReferralConfirmation = this.rescindReferralConfirmation.bind(
      this
    );
    this.handleConfirmBox = this.handleConfirmBox.bind(this);
    this.patientNotesOpen = this.patientNotesOpen.bind(this);
    this.viewReferralPatients = this.viewReferralPatients.bind(this);
  }

  downloadFile = async (fileName,fileUrl) => {
    let showNotification = {};
    try {
        let showNotification = {
            title: 'Fax Details',
            message: 'Please wait, Downloading Fax files',
            type: "info"
        };
        userService.showNotification(showNotification);
        let urlArr = fileUrl.split('/')
        let urlArrEndPoint = urlArr[urlArr.length - 2] + "/" + urlArr[urlArr.length - 1];
        let dataUses = "";
        dataUses = "?key=" + urlArrEndPoint;
        let sheetData = await userService.fetchCommentSheetsData(apiPath.downloadCommentFile + dataUses, fileName);
    } catch (error) {
        showNotification = {
            title: 'Fax',
            message: 'No Fax available.',
            type: "danger"
        };
        userService.showNotification(showNotification);
    }
    this.setState({
        loading: false,
    })
    return false;
}

  async fetchProviders() {
    let url;
    url = apiPath.findAwaitingProvider + "?refferalId=" + this.state.refferalId;
    this.setState({
      loading: true,
    });
    this.props.loaderStatus(true);
    const response = await fetch(url, {
      method: "GET",
      headers: generalAuthTokenHeader(),
    })
      .then((response) => {
        if (response.status === 400) {
        } else if (response.ok) {
          let totalPatientCount = response.headers.get("X-Total-Count");
          this.setState({
            totalPatients: totalPatientCount,
          });
        } else if (response.status === 401) {
        } else {
        }
        return response.json();
      })
      .then((data) => {
        this.props.loaderStatus(false);
        if (data && data[0]) {
          data = data[0];
          if (data && data.refferalId) {
            if (this.state.refferalStatus != data.refferalStatus) {
              this.props.updatePatient();
              return false;
            }
            let refferalProviderEntity = [];
            let refferalPatientEntity = [];
            let refferalProviderComment = [];
            if (
              data.refferalProviderEntity &&
              data.refferalProviderEntity.length
            ) {
              refferalProviderEntity = data.refferalProviderEntity;
            }
            if (
              data.refferalPatientEntity &&
              data.refferalPatientEntity.length
            ) {
              refferalPatientEntity = data.refferalPatientEntity;
            }
            if (
              data.refferalProviderComment &&
              data.refferalProviderComment.length
            ) {
              refferalProviderComment = data.refferalProviderComment.sort(
                (a, b) => (a.createdDateTime > b.createdDateTime ? 1 : -1)
              );
            }
           
            this.setState({
              refferalProviderEntity: refferalProviderEntity,
              refferalPatientEntity: refferalPatientEntity,
              refferalProviderCommentDP: refferalProviderComment,
              providerData: data,
              
              
            });
          }
        }
        this.setState({
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        return response;
      });
  }
  componentDidMount() {
    this.fetchProviders();
  }
  componentDidUpdate() {
    if (
      this.props.reduxLoadFlag != undefined &&
      this.state.reduxLoadFlag != this.props.reduxLoadFlag
    ) {
      let categoryList = [];
      let insuranceList = [];
      let hospitalList = [];
      let patientStatusList = [];
      let userInfo = {};
      let totalCategory = {
        1: {
          mainCategory: [],
          subCategory: [],
          childCategory: [],
        },
        2: {
          mainCategory: [],
          subCategory: [],
          childCategory: [],
        },
        3: {
          mainCategory: [],
          subCategory: [],
          childCategory: [],
        },
        4: {
          mainCategory: [],
          subCategory: [],
          childCategory: [],
        },
        5: {
          mainCategory: [],
          subCategory: [],
          childCategory: [],
        },
      };
      if (this.props.categoryListRedux) {
        let selectedcategoryList = this.props.categoryListRedux;
        categoryList = selectedcategoryList ? selectedcategoryList : [];

        if (categoryList && categoryList.length) {
          categoryList.map((cList, key) => {
            // main loop
            if (cList.catagoryId == 1) {
              const { children } = cList;
              children.map((mChildren, mKey) => {
                if (mChildren.children && mChildren.children.length) {
                  mChildren.children.map((cChildren, cKey) => {
                    totalCategory[1].childCategory.push(cChildren.catagoryId);
                  });
                } else {
                  totalCategory[1].subCategory.push(mChildren.catagoryId);
                }
              });
            }
            if (cList.catagoryId == 2) {
              const { children } = cList;
              children.map((mChildren, mKey) => {
                if (mChildren.children && mChildren.children.length) {
                  mChildren.children.map((cChildren, cKey) => {
                    totalCategory[2].childCategory.push(cChildren.catagoryId);
                  });
                } else {
                  totalCategory[2].subCategory.push(mChildren.catagoryId);
                }
              });
            }
            if (cList.catagoryId == 3) {
              const { children } = cList;
              children.map((mChildren, mKey) => {
                if (mChildren.children && mChildren.children.length) {
                  mChildren.children.map((cChildren, cKey) => {
                    totalCategory[3].childCategory.push(cChildren.catagoryId);
                  });
                } else {
                  totalCategory[3].subCategory.push(mChildren.catagoryId);
                }
              });
            }
            if (cList.catagoryId == 4) {
              const { children } = cList;
              children.map((mChildren, mKey) => {
                if (mChildren.children && mChildren.children.length) {
                  mChildren.children.map((cChildren, cKey) => {
                    totalCategory[4].childCategory.push(cChildren.catagoryId);
                  });
                } else {
                  totalCategory[4].subCategory.push(mChildren.catagoryId);
                }
              });
            }
            if (cList.catagoryId == 5) {
              const { children } = cList;
              children.map((mChildren, mKey) => {
                if (mChildren.children && mChildren.children.length) {
                  mChildren.children.map((cChildren, cKey) => {
                    totalCategory[5].childCategory.push(cChildren.catagoryId);
                  });
                } else {
                  totalCategory[5].subCategory.push(mChildren.catagoryId);
                }
              });
            }
          });
        }
      }
      if (this.props.insuranceListRedux) {
        let selectedinsuranceList = this.props.insuranceListRedux;
        insuranceList = selectedinsuranceList ? selectedinsuranceList : [];
      }
      if (this.props.hospitalListRedux) {
        let selectedhospitalList = this.props.hospitalListRedux;
        hospitalList = selectedhospitalList ? selectedhospitalList : [];
      }
      if (this.props.patientStatusListRedux) {
        let selectedpatientStatusList = this.props.patientStatusListRedux;
        patientStatusList = selectedpatientStatusList
          ? selectedpatientStatusList
          : [];
      }
      if (this.props.userInfoRedux) {
        let userInfoRedux = this.props.userInfoRedux;
        userInfo = userInfoRedux ? userInfoRedux : {};
      }
      this.setState({
        reduxLoadFlag: this.props.reduxLoadFlag,
        categoryList: categoryList,
        insuranceList: insuranceList,
        hospitalList: hospitalList,
        patientStatusList: patientStatusList,
        userInfo: userInfo,
        totalCategoryIds: totalCategory,
      });
    }
  }
  componentWillUnmount() {
    this.subscription = findProviderService.clearFindProvidersList();
  }
  fetchMoreData = () => {
    setTimeout(() => {
      if (this.state.fetchMore) {
        this.setState({
          page: this.state.page + 1,
        });
      }
    }, 2500);
  };
  searchProviderText = (event) => {
    const searchText = event.target.value.trim();
    this.setState({
      searchKeyword: searchText,
    });
  };
  searchProvider() {
    let allProviderList =
      this.state.providerData && this.state.providerData.refferalProviderEntity
        ? this.state.providerData.refferalProviderEntity
        : [];
    const searchText = this.state.searchKeyword;
    let allLocalProviderList = [];
    if (searchText) {
      allProviderList = allProviderList.filter((v, i) => {
        if (v.provider && v.provider.providerName) {
          return (
            v.provider.providerName
              .toLowerCase()
              .indexOf(searchText.toLowerCase().trim()) !== -1
          );
        }
      });
      this.setState({
        refferalProviderEntity: allProviderList,
      });
    }
    this.setState({
      refferalProviderEntity: allProviderList,
    });
  }
  providerStatusDropdown = (
    event,
    providerID,
    referralStatusId,
    referralProviderName
  ) => {
    if (
      referralStatusId == 5 ||
      referralStatusId == 7 ||
      referralStatusId == 3 ||
      referralStatusId == 1
    ) {
      return;
    }
    let referralStatusLocal = [];
    if (referralStatusId === 2 || referralStatusId === 4) {
      referralStatusLocal = REFERRAL_STATUS.filter((v, i) => {
        if (v.id == 7 || v.id == referralStatusId) {
          return v;
        }
      });
    }
    if (referralStatusId === 6) {
      referralStatusLocal = REFERRAL_STATUS.filter((v, i) => {
        if (v.id == 7 || v.id == 3 || v.id == referralStatusId) {
          return v;
        }
      });
    }
    this.setState({
      providerStatus: event.currentTarget,
      providerIdForStatusChange: providerID,
      providerReferralStatusId: referralStatusId,
      referralStatus: referralStatusLocal,
      referralProviderName: referralProviderName,
    });
  };
  handleCloseForProviderStatus = () => {
    this.setState({
      providerStatus: null,
      confirmBoxStatus: false,
    });
  };
  selectProviderCheckBox(providerId) {
    this.props.selectedProviderDataCallback(providerId);
  }
  async rescindReferral() {
    let showNotification = "";
    let data = {};
    this.setState({
      loading: true,
      openConfirmationBox: false,
    });
    this.props.loaderStatus(true);
    let url =
      apiPath.rescindReferralDp + "?refferalId=" + this.state.refferalId;
    const response = await fetch(url, {
      method: "GET",
      headers: generalAuthTokenHeader(),
    })
      .then((response) => {
        if (response.status === 400) {
          showNotification = {
            title: "Rescind Status",
            message: "Bad response from server1",
            type: "danger",
          };
          this.props.loaderStatus(false);
          return response.json();
        } else if (response.ok) {
          showNotification = {
            title: "Rescind Status",
            message: "Rescinded all providers successfull",
            type: "success",
          };
          this.props.updatePatient();
          return response;
        } else {
          showNotification = {
            title: "Rescind Status",
            message: "Bad response from server2.",
            type: "danger",
          };
          this.props.loaderStatus(false);
        }
        this.setState({
          loading: false,
        });
        return response;
      })
      .then((data) => {
        if (data.status == 400) {
          showNotification = {
            title: "Rescind Status",
            message: data.title,
            type: "danger",
          };
        }
        userService.showNotification(showNotification);
        return true;
      })
      .catch((error) => {
        showNotification = {
          title: "Rescind Status",
          message: "Something went wrong.Please try after sometime..",
          type: "danger",
        };
        userService.showNotification(showNotification);
        this.setState({
          loading: false,
        });
        this.props.loaderStatus(false);
      });
  }
  rescindReferralConfirmation() {
    this.setState({
      openConfirmationBox: true,
    });
  }
  handleConfirmBox() {
    this.setState({
      openConfirmationBox: false,
      referralPatientModal: false,
    });
  }
  statusConfirmBox(referralStatusId, referralProviderName) {
    if (
      referralStatusId &&
      this.state.providerReferralStatusId != referralStatusId
    ) {
      this.setState({
        newReferralStatusId: referralStatusId,
        confirmBoxStatus: true,
      });
    } else {
      this.handleCloseForProviderStatus();
      return true;
    }
  }
  selectProviderStatus = async () => {
    this.setState({
      confirmBoxStatus: false,
    });
    let referralStatusId = this.state.newReferralStatusId;
    let statusAnswer = this.state.statusAnswer;
    if (
      referralStatusId &&
      this.state.providerReferralStatusId != referralStatusId
    ) {
      this.props.loaderStatus(true);
      let showNotification = "";
      let data = {
        providerId: this.state.providerIdForStatusChange,
        refferalId: this.state.refferalId,
        statusId: referralStatusId,
        chooserProviderPatient: this.state.statusAnswer,
      };
      const response = await fetch(apiPath.changeProviderStatus, {
        method: "PUT",
        headers: generalAuthTokenHeader(),
        body: JSON.stringify(data),
        data: JSON.stringify(data),
      })
        .then((response) => {
          if (response.status === 400) {
            showNotification = {
              title: "Provider Status",
              message: "Bad response from server",
              type: "danger",
            };
          } else if (response.ok) {
            showNotification = {
              title: "Provider Status",
              message: "Provider status change successfully.",
              type: "success",
            };
          } else {
            showNotification = {
              title: "Provider Status",
              message: "Bad response from server.",
              type: "danger",
            };
          }
          return response.json();
        })
        .then((data) => {
          if (data.status == 400) {
            showNotification = {
              title: "",
              message: data.title,
              type: "danger",
            };
            
          }
          if (referralStatusId == 3 || referralStatusId == 1) {
            this.handleCloseForProviderStatus();
            this.props.updatePatient();
          } else {
            this.props.loaderStatus(false);
            this.fetchProviders();
            this.handleCloseForProviderStatus();
          }
          return true;
        })
        .catch((error) => {
          showNotification = {
            title: "Provider Status",
            message: "Something went wrong.Please try after sometime..",
            type: "danger",
          };
          this.props.loaderStatus(false);
        });
      userService.showNotification(showNotification);
    } else {
      this.handleCloseForProviderStatus();
      return true;
    }
  };
  patientCriteriaModalOpen = () => {
    this.setState({
      patientCriteriaModalStatus: true,
    });
  };
  patientCriteriaModalClose = () => {
    this.setState({
      patientCriteriaModalStatus: false,
    });
  };
  searchReturn(event) {
    if (event.target.keyCode === 13 || event.key === "Enter") {
      event.preventDefault();
      this.searchProvider();
    }
  }
  setFilterProviderList = (filter) => {
    // do nothing
  };
  patientNotesOpen() {
    let notesStatus = this.state.patientNotesStatus ? false : true;
    this.setState({
      patientNotesStatus: notesStatus,
    });
  }
  viewReferralPatients() {
    this.setState({
      referralPatientModal: true,
    });
  }
  
  toggleTransportDetailsModal = () => {
    const transportDetailsModal = this.state.transportDetailsModal;

    this.setState({
      transportDetailsModal: !transportDetailsModal,
    });
  };

  toggleFaxDetailsModal = () => {
    const faxDetailsModal = this.state.faxDetailsModal;

    this.setState({
      faxDetailsModal: !faxDetailsModal,
    });
  };
  handleChangeInput = (event) => {
    const name = event.target.name;
    const value = event.target.value.trimStart();
    this.setState({
      [name]: value,
    });
  };
  downloadFileFax = async (fileName,fileUrl) => {
    let showNotification = {};
    try {
        let showNotification = {
            title: 'Fax Details',
            message: 'Please wait, Downloading Fax files',
            type: "info"
        };
        userService.showNotification(showNotification);
        let urlArr = fileUrl.split('/')
        let urlArrEndPoint = urlArr[urlArr.length - 2] + "/" + urlArr[urlArr.length - 1];
        let dataUses = "";
        dataUses = "?key=" + urlArrEndPoint;
        let sheetData = await userService.fetchCommentSheetsData(apiPath.downloadCommentFile + dataUses, fileName);
    } catch (error) {
        showNotification = {
            title: 'Fax',
            message: 'No Fax available.',
            type: "danger"
        };
        userService.showNotification(showNotification);
    }
    this.setState({
        loading: false,
    })
    return false;
}


  downloadFile = async (fileName, fileUrl) => {
    this.setState({
      loading: true,
    });
    let showNotification = {};
    try {
      downloadMedia(fileUrl, fileName);
    } catch (error) {
      showNotification = {
        title: "Facesheet",
        message: "No facesheet available.",
        type: "danger",
      };
      userService.showNotification(showNotification);
    }
    this.setState({
      loading: false,
    });
    return false;
  };

  async MakeFinalized(providerId) {
    const { refferalId } = this.state;
    let showNotification = "";
  
    try {
      const response = await fetch(apiPath.updateFaxFinalized + "?refferalId=" + refferalId + "&providerId=" + providerId, {
        method: "PUT",
        headers: generalAuthTokenHeader(),
      });
  
      if (response.status === 400) {
       
        showNotification = {
          title: "Provider Status",
          message: "Bad response from server",
          type: "danger",
        };
      } else if (response.ok) {
       
        showNotification = {
          title: "Provider Status",
          message: "Provider status changed successfully.",
          type: "success",
        };
      } else {
       
        showNotification = {
          title: "Provider Status",
          message: "Bad response from server.",
          type: "danger",
        };
      }
  
      
    } catch (error) {
      
      showNotification = {
        title: "Provider Status",
        message: "Something went wrong. Please try again later.",
        type: "danger",
      };
      console.error("Error finalizing provider status:", error);
    }
  
    this.fetchProviders();
    this.handleCloseForProviderStatus();
    this.state.spinner.setAttribute("hidden", "true");
    userService.showNotification(showNotification);
  }
  
  
  

  render() {
    const {
      refferalProviderEntity,
      refferalPatientEntity,
      providerData,
      loading,
      isCatAvailable,
      selectedCategoryId,
      totalCategoryIds,
      refferalProviderCommentDP,
    } = this.state;
    let availableCatUser = [];
    console.log("providerData",this.state.providerData);
    return (
      <>
        {this.state.categoryId && (
          <PatientCriteria
            modalStatus={this.state.patientCriteriaModalStatus}
            modalClose={this.patientCriteriaModalClose}
            selectCategoryId={this.state.categoryId}
            patientId={this.state.patientId}
            patientInfo={this.state.patientInfo}
            setFilterProviderList={this.setFilterProviderList}
            readOnly={true}
          />
        )}
        <PatientNotes
          modalStatus={this.state.patientNotesStatus}
          selectCategoryId={this.state.categoryId}
          patientInfo={this.state.patientInfo}
        />
        <Dialog
          open={this.state.openConfirmationBox}
          maxWidth={"xs"}
          onClose={this.handleConfirmBox}
          TransitionComponent={Transition}
          aria-labelledby="form-dialog-title"
          className="add-modal front-modal "
        >
          <DialogTitle className="add-modal-title" id="form-dialog-title">
            Rescind Referral
            <IconButton
              className="closeButton"
              aria-label="close"
              onClick={this.handleCloseForProviderStatus}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box
              width={1}
              display="flex"
              justifyContent="flex-start"
              mb={1}
              mt={1}
            >
              <Box pr={2}>Do you want rescind this referral ?</Box>
            </Box>
          </DialogContent>
          <DialogActions className="modal-actions" justify="center">
            <Button
              type="button"
              className="btn1"
              onClick={this.rescindReferral}
            >
              Confirm
            </Button>
            <Button onClick={this.handleConfirmBox} className="cancel-link">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.confirmBoxStatus}
          maxWidth={"xs"}
          onClose={this.handleConfirmBox}
          TransitionComponent={Transition}
          aria-labelledby="form-dialog-title"
          className="add-modal front-modal "
        >
          <DialogTitle className="add-modal-title" id="form-dialog-title">
            Status Change
            <IconButton
              className="closeButton"
              aria-label="close"
              onClick={this.handleCloseForProviderStatus}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box
              width={1}
              display="flex"
              justifyContent="flex-start"
              mb={1}
              mt={1}
            >
              <Box pr={2}>
                This will{" "}
                <b>
                  {REFERRAL_STATUS.map((rStatus) => {
                    if (rStatus.id === this.state.newReferralStatusId) {
                      let status =
                        rStatus.name == "Finalized" ? "Finalize" : rStatus.name;
                      return " " + status;
                    }
                  })}
                </b>{" "}
                this referral with <b>{this.state.referralProviderName}</b>
              </Box>
            </Box>

            {this.state.newReferralStatusId == "3" ? (
              <>
                <Box
                  width={1}
                  display="flex"
                  justifyContent="flex-start"
                  mb={1}
                  mt={1}
                >
                  {" "}
                  <Box pr={6}>Who chose the provider for the patient?</Box>
                </Box>
                <Box
                  width={1}
                  display="flex"
                  justifyContent="flex-start"
                  mb={1}
                  mt={1}
                >
                  <Box width={1}>
                    <TextField
                      InputLabelProps={{ className: "required-label" }}
                      label=""
                      placeholder="Answer*"
                      name="statusAnswer"
                      value={this.state.statusAnswer}
                      onChange={this.handleChangeInput}
                      inputProps={{ maxLength: MAX_INPUT_LENGTH_TEXT_BOX }}
                    />
                  </Box>
                </Box>
              </>
            ) : (
              ""
            )}
          </DialogContent>
          <DialogActions className="modal-actions" justify="center">
            <Button
              type="button"
              className="btn1"
              onClick={this.selectProviderStatus}
              disabled={
                this.state.newReferralStatusId == "3" &&
                !this.state.statusAnswer
                  ? true
                  : false
              }
            >
              Confirm
            </Button>
            <Button
              onClick={this.handleCloseForProviderStatus}
              className="cancel-link"
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.referralPatientModal}
          maxWidth={"sm"}
          onClose={this.handleConfirmBox}
          TransitionComponent={Transition}
          aria-labelledby="form-dialog-title"
          className="add-modal front-modal ViewInvitemodal"
        >
          <DialogTitle className="add-modal-title " id="form-dialog-title">
            Referral Patients
            <IconButton
              className="closeButton"
              aria-label="close"
              onClick={this.handleConfirmBox}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box
              display="flex"
              flexDirection="column"
              width={1}
              className="patient-list-page AwaitingProviderTabPanelCSS patient-list-page-new patient-info-page-new  FinalisedTab ViewInvite"
            >
              <Box
                width={1}
                display="flex"
                className="divcontainer-tab "
                mb={2}
              >
                <Box pr={1} className="title-box">
                  <h4 className="label">Referral ID</h4>
                  <h4 className="value">{this.state.refferalId}</h4>
                </Box>
                <Box pr={1} className="title-box">
                  <h4 className="label">Total Patients</h4>
                  <h4 className="value">
                    {refferalPatientEntity && refferalPatientEntity.length
                      ? refferalPatientEntity.length
                      : 0}
                  </h4>
                </Box>
                <Box pr={1} className="title-box">
                  <h4 className="label">Referral Sent</h4>
                  <h4 className="value">{providerData.refferalSendTime}</h4>
                </Box>
              </Box>
              <Box
                width={1}
                className="left-content-list left-content-list-New"
              >
                <div className="PatientList view-ref-cover ">
                  <Box
                    universal={true}
                    className="YOnlytScrollbars min-height-threehundred"
                  >
                    {/* <Scrollbars style={{ height: "300px" }} universal={true} className="YOnlytScrollbars "> */}
                    {refferalPatientEntity && refferalPatientEntity.length
                      ? refferalPatientEntity.map((listItem, index) => {
                          const {
                            patientId,
                            patientEntity,
                            providerSearchHistoryEntity,
                          } = listItem;
                          let categoryId = [];
                          const urlParams = new URLSearchParams(
                            providerSearchHistoryEntity
                              ? providerSearchHistoryEntity.search
                              : "subCategoryId"
                          );
                          let subCategoryId = urlParams.get("subCategoryId");
                          subCategoryId = subCategoryId.split(",");
                          if (subCategoryId && subCategoryId[1]) {
                            categoryId = subCategoryId[1]
                              .split("-")
                              .map((item) => Number(item));
                          }
                          return (
                            <Box
                              width={1}
                              display="flex"
                              flexDirection="row"
                              mb={2}
                              pr={2}
                              key={index}
                              className="AllReferrals-page"
                            >
                              <div className="list-item-content w-100 p-right">
                                <Box
                                  className="list-item-content-data"
                                  width={1}
                                  pt={2}
                                  display="flex"
                                  flexDirection="row"
                                >
                                  <Box width={1 / 2} pr={1} className="pointer">
                                    <div className="user-info-tab-finalised">
                                      <div className="name">
                                        {patientEntity.lastName +
                                          ", " +
                                          patientEntity.firstName}
                                      </div>
                                      <div className="date-insurance">
                                        Est Discharge{" "}
                                        {
                                          patientEntity.formattedEstimatedDischargeDate
                                        }
                                      </div>
                                    </div>
                                    <div className="user-info-tab-finalised">
                                      Insurance:{" "}
                                      {this.state.insuranceList.map(
                                        (insurance) => {
                                          if (
                                            insurance.insuranceId ==
                                            patientEntity.insuranceId
                                          ) {
                                            return insurance.insuranceName;
                                          }
                                        }
                                      )}
                                    </div>
                                  </Box>
                                  <Box
                                    pl={1}
                                    width={1 / 2}
                                    className="Ref-category"
                                  >
                                    <Box
                                      width={1}
                                      display="flex"
                                      flexWrap="wrap"
                                      className=""
                                    >
                                      {this.state.categoryList &&
                                      this.state.categoryList.length &&
                                      categoryId &&
                                      categoryId.length
                                        ? this.state.categoryList.map(
                                            (cList, key) => {
                                              if (
                                                categoryId.includes(cList.id)
                                              ) {
                                                return (
                                                  <Box
                                                    width={1 / 2}
                                                    mr={1}
                                                    mb={1}
                                                    className="Ref-categorybox"
                                                    key={key}
                                                  >
                                                    {cList.categoryValue}
                                                  </Box>
                                                );
                                              }
                                            }
                                          )
                                        : null}
                                    </Box>
                                  </Box>
                                </Box>
                              </div>
                            </Box>
                          );
                        })
                      : "No Patient Found"}
                  </Box>
                </div>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>

        <TransportDetails
          open={this.state.transportDetailsModal}
          toggle={this.toggleTransportDetailsModal}
          transportDetails={this.state.providerData.refferalTransportEntity}
        />
         <FaxDetails
          open={this.state.faxDetailsModal}
          toggle={this.toggleFaxDetailsModal}
          faxDetails={this.state.providerData.faxStatusTrack}
        />
        <Box
          display="flex"
          flexDirection="row"
          width={1}
          className="patient-list-page AwaitingProviderTabPanelCSS patient-list-page-new patient-info-page-new withoutCheckboXContent AwaitingTab"
        >
          {providerData && providerData.refferalId ? (
            <Box pr={1} pl={0} className="left-content-box ">
              <div className="left-content-header">
                <Box display="flex" pr={2} className="divcontainer-tab" mb={2}>
                  <Box pr={1} className="title-box">
                    <h4 className="label">Referral ID</h4>
                    <h4 className="value">{this.state.refferalId}</h4>
                  </Box>
                  <Box pr={1} className="title-box ReferralTypeHeadingFlex">
                    <h4 className="label">Referral Type</h4>
                    {refferalProviderCommentDP.length &&
                    refferalProviderCommentDP[1] &&
                    refferalProviderCommentDP[1].comment ? (
                      <CustomTooltip
                        title={refferalProviderCommentDP[0].comment}
                      >
                        <div>
                          <h4 className="value">
                            {REFERRAL_TYPE[providerData.refferalType]}
                          </h4>{" "}
                          <img src={commentIcon} />
                        </div>
                      </CustomTooltip>
                    ) : (
                      <h4 className="value">
                        {REFERRAL_TYPE[providerData.refferalType]}
                      </h4>
                    )}
                  </Box>
                  <Box pr={1} className="title-box">
                    <h4 className="label">Referral Sent</h4>
                    <h4 className="value">{providerData.refferalSendTime}</h4>
                  </Box>
                  <Box
                    className="action-box-tab"
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-end"
                  >
                    <Box pl={1} pr={1}>
                      <Tooltip title="Search By Name" placement="top" arrow>
                        <Paper component="form" className="search-box-cover">
                          <InputBase
                            className="search-box"
                            placeholder="Enter search here..."
                            onChange={this.searchProviderText}
                            onKeyPress={this.searchReturn}
                          />
                          <IconButton
                            className=""
                            aria-label="search"
                            onClick={this.searchProvider}
                          >
                            <SearchIcon />
                          </IconButton>
                        </Paper>
                      </Tooltip>
                    </Box>
                    {this.state.providerData.refferalTransportEntity && (
                      <Box pl={1} className="action-filter">
                        <button
                          className="pointer btn4"
                          onClick={() => this.toggleTransportDetailsModal()}
                        >
                          Transport Details
                        </button>
                      </Box>
                    )}
                    {this.state.providerData.faxStatusTrack && (
                      <Box pl={1} className="action-filter">
                      </Box>
                    )}
                    <Box pl={1} className="action-filter">
                      <button
                        className="pointer btn4"
                        onClick={() => this.viewReferralPatients()}
                      >
                        View Referral Patients
                      </button>
                    </Box>

                    <Box
                      pl={1}
                      className="action-filter"
                      style={{ display: "" }}
                    >
                      <button
                        className="pointer btn4"
                        onClick={() => this.rescindReferralConfirmation()}
                      >
                        Rescind Referral
                      </button>
                    </Box>
                  </Box>
                </Box>
              </div>
              <Box
                width={1}
                className="left-content-list left-content-list-New"
              >
                <div className="PatientList scrollmanagment">
                  <Scrollbars
                    style={{ height: "calc(100vh - 518px)" }}
                    universal={true}
                    className="YOnlytScrollbars TabScroll"
                  >
                    <InfiniteScroll
                      pageStart={0}
                      loadMore={this.fetchMoreData}
                      hasMore={this.state.fetchMore}
                      loader={
                        <div className="loader" key={0}>
                          Loading ...
                        </div>
                      }
                      useWindow={false}
                    >
                      {refferalProviderEntity &&
                      refferalProviderEntity.length ? (
                        refferalProviderEntity
                          .sort((a, b) =>
                            a.provider.providerName > b.provider.providerName
                              ? 1
                              : -1
                          )
                          .map((listItem, index) => {
                            const {
                              provider,
                              masterRefferalStatusEntity,
                              refferalProviderComment,
                            } = listItem;
                            return (
                              <Box
                                width={1}
                                display="flex"
                                flexDirection="row"
                                mb={2}
                                pr={2}
                                key={index}
                              >
                                <div className="list-item-content p-right">
                                  <Box
                                    className="list-item-content-data"
                                    width={1}
                                    display="flex"
                                    flexDirection="row"
                                  >
                                    <Box
                                      pr={1}
                                      className="pointer"
                                      onClick={() =>
                                        this.selectProviderCheckBox(
                                          listItem.providerId
                                        )
                                      }
                                    >
                                      <div className="user-info-tab">
                                        <div
                                          className=""
                                          title={
                                            provider.organizationEntity
                                              ? provider.organizationEntity
                                                  .orgName
                                              : provider.providerName
                                          }
                                        >
                                          {provider.organizationEntity
                                            ? provider.organizationEntity
                                                .orgName
                                            : provider.providerName}{" "} ...
                                          <span className="infoicon-svg">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="18"
                                              height="18"
                                              viewBox="0 0 15 15"
                                            >
                                              <path
                                                id="information_outline"
                                                data-name="information outline"
                                                d="M7.4,6.2H8.6V5H7.4M8,12.8A4.8,4.8,0,1,1,12.8,8,4.806,4.806,0,0,1,8,12.8ZM8,2a6,6,0,1,0,6,6A6,6,0,0,0,8,2Zm-.6,9H8.6V7.4H7.4Z"
                                                transform="translate(-1.999 -1.998)"
                                                fill="#0D81B4"
                                              />
                                            </svg>
                                          </span>
                                        </div>
                                        <div className="date-insurance">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="14.609"
                                            height="14.608"
                                            viewBox="0 0 14.609 14.608"
                                          >
                                            <path
                                              id="phone"
                                              d="M5.94,9.324a12.228,12.228,0,0,0,5.347,5.344l1.785-1.788a.813.813,0,0,1,.825-.2,9.326,9.326,0,0,0,2.9.461.811.811,0,0,1,.812.812v2.84a.811.811,0,0,1-.812.812A13.8,13.8,0,0,1,3,3.81.811.811,0,0,1,3.81,3h2.84a.811.811,0,0,1,.812.812,9.326,9.326,0,0,0,.461,2.9.811.811,0,0,1-.2.824Z"
                                              transform="translate(-2.999 -2.998)"
                                              fill="#01a7a6"
                                            />
                                          </svg>
                                          <span className="phoneNumber">
                                            {provider.user.phoneNumber
                                              ? "(" +
                                                provider.user.phoneNumber.match(
                                                  /(\d{3})(\d{3})(\d{4})/
                                                )[1] +
                                                ") " +
                                                provider.user.phoneNumber.match(
                                                  /(\d{3})(\d{3})(\d{4})/
                                                )[2] +
                                                "-" +
                                                provider.user.phoneNumber.match(
                                                  /(\d{3})(\d{3})(\d{4})/
                                                )[3]
                                              : "N/A"}
                                          </span>
                                        </div>
                                      </div>
                                    </Box>

                                    <Box
                                      className="OwlCarousel-box"
                                      pr={2}
                                      my={"auto"}
                                    >
                                      {provider.array &&
                                      provider.array.length ? (
                                        <OwlCarousel
                                          className="owl-theme owl-tab-theme"
                                          key={`carousel_${provider.array.length}`}
                                          {...this.state.owlCarouselOptions}
                                        >
                                          {provider.array
                                            .sort((a, b) =>
                                              a.masterCategories.categoryValue >
                                              b.masterCategories.categoryValue
                                                ? 1
                                                : -1
                                            )
                                            .map((el, index) => {
                                              const {
                                                parent,
                                                masterCategories,
                                                value,
                                                children,
                                                categoryId,
                                              } = el;
                                              if (
                                                (totalCategoryIds[
                                                  providerData.categoryId
                                                ]?.subCategory?.includes(
                                                  categoryId
                                                ) ||
                                                  totalCategoryIds[
                                                    providerData.categoryId
                                                  ]?.childCategory?.includes(
                                                    categoryId
                                                  )) &&
                                                value != ""
                                              ) {
                                                if (
                                                  !availableCatUser.includes(
                                                    provider.id
                                                  )
                                                ) {
                                                  availableCatUser.push(
                                                    provider.id
                                                  );
                                                }
                                                return (
                                                  <Box
                                                    className="item menu-item"
                                                    key={index}
                                                    border={1}
                                                  >
                                                    <p className="category-type">
                                                      {
                                                        masterCategories.categoryValue
                                                      }
                                                    </p>
                                                    {value && value != "" ? (
                                                      <p className="amount-value">
                                                        {value && value != ""
                                                          ? "$" + value
                                                          : "$0"}
                                                      </p>
                                                    ) : null}
                                                  </Box>
                                                );
                                              }
                                            })}
                                        </OwlCarousel>
                                      ) : null}
                                    </Box>
                                    <Box pr={1} className="pointer">
                                      <Box className="user-msg-tab">
                                        {listItem.refferalProviderComment &&
                                        listItem.refferalProviderComment.length
                                          ? listItem.refferalProviderComment.map(
                                              (cList) => {
                                                if (
                                                  cList.sender == "Provider"
                                                ) {
                                                  return (
                                                    <CustomTooltip
                                                      title={cList.comment}
                                                    >
                                                      <div>
                                                        <b>{cList.sender}: </b>
                                                        {cList.comment}
                                                      </div>
                                                    </CustomTooltip>
                                                  );
                                                }
                                              }
                                            )
                                          : null}
                                      </Box>
                                    </Box>

                                    <Box pl={1} pr={2} my={"auto"}>
                                      <Menu
                                        id="lock-menu"
                                        anchorEl={this.state.providerStatus}
                                        keepMounted
                                        open={Boolean(
                                          this.state.providerStatus
                                        )}
                                        onClose={
                                          this.handleCloseForProviderStatus
                                        }
                                      >
                                        {this.state.referralStatus &&
                                        this.state.referralStatus.length
                                          ? this.state.referralStatus.map(
                                              (rStatus, key) => (
                                                <MenuItem
                                                  key={key}
                                                  value={rStatus.id}
                                                  onClick={() =>
                                                    this.statusConfirmBox(
                                                      rStatus.id,
                                                      provider.providerName
                                                    )
                                                  }
                                                >
                                                  {rStatus.name}
                                                </MenuItem>
                                              )
                                            )
                                          : null}
                                      </Menu>
                                     
                                      <Box display="flex" flexDirection="row" style={{ whiteSpace: "nowrap" }}>
                                     
                                      {/* {providerData.faxUploadHistory.map((faxHistory) => ( */}
                                       {  providerData.faxStatus === "Sent" &&  providerData.faxUploadHistory.length > 1 && (
                                            <div className="tags-cover">
                                              <Button
                                                className="pointer btn1"
                                               // onClick={() => this.downloadFileFax(faxHistory.faxFileName, faxHistory.faxFileUrl,console.log(this.state.provider.providerId))}
                                               onClick={() => {
                                                providerData.faxUploadHistory.forEach((faxHistory) => {
                                                  if (faxHistory.providerId === provider.organizationEntity.providerId) {
                                                    // console.log(faxHistory.providerId); 
                                                    // console.log("overall", provider.organizationEntity);  
                                                    // console.log("detailsfax", faxHistory.faxFileName, faxHistory.faxFileUrl);                                                     
                                                   
                                                     this.downloadFileFax(faxHistory.faxFileName, faxHistory.faxFileUrl);   
                                                  }
                                                });}}
                                              >
                                                Fax Details
											
                                              </Button>
                                            </div>
                                          
                                        )}
                                      {providerData.refferalProviderComment.length > 1 && providerData.faxStatus ==="Sent" &&(  
                                           <div className="tags-cover">
                                         <Button
                                          className="pointer btn1"
                                          onClick={() => {
                                            this.MakeFinalized(provider.organizationEntity.providerId);           
                                          }}
                                        >
                                          Make Finalized
                                        </Button>   
                                        </div>
                                      )}
                                      {providerData.faxStatus != null &&  providerData.faxStatus != "" ? 
                                        <div className="tags-cover">
                                          <CustomTooltip
                                            title="Fax Status" className={`tag  ${masterRefferalStatusEntity.refferalStatusValue}`}
                                            >
                                             <div>
                                              Fax:{providerData.faxStatus}
                                            </div>
                                          </CustomTooltip>
                                        </div>
                                          :""}
                                        <div className="tags-cover">
                                          <Button
                                            className={`pointer btn1  ${masterRefferalStatusEntity.refferalStatusValue}`}
                                            disabled={
                                              masterRefferalStatusEntity.refferalStatusValue ===
                                              "Dropped"
                                            }
                                            onClick={(event) =>
                                              this.providerStatusDropdown(
                                                event,
                                                listItem.providerId,
                                                masterRefferalStatusEntity.id,
                                                provider.providerName
                                              )
                                            }
                                          >
                                            {
                                              masterRefferalStatusEntity.refferalStatusValue
                                            }
                                          </Button>
                                        </div>
                                      </Box>
                                    </Box>
                                  </Box>
                                </div>
                                
                              </Box>
                            );
                          })
                      ) : (
                        <div className="no-found-text">Providers Not Found</div>
                      )}
                    </InfiniteScroll>
                  </Scrollbars>
                </div>
              </Box>
            </Box>
          ) : loading ? (
            <Box pr={3} pl={2} width={1} display="flex" justifyContent="center">
              <CircularProgress size={24} className="CircularProgress-CSS " />
            </Box>
          ) : (
            <div className="no-found-text">Providers Not Available</div>
          )}
        </Box>
      </>
    );
  }
}

export const AwaitingProviderTabPanel = connect(
  mapStateToProps,
  mapDispatchToProps
)(AwaitingProviderTabPanelClass);
export default AwaitingProviderTabPanel;
