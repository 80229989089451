import React from "react";
import Box from "@material-ui/core/Box";
import CloseIcon from "@material-ui/icons/Close";
import WarningIcon from "@material-ui/icons/Warning";
import {
  IconButton,
  Link as MaterialLink,
  LinearProgress,
} from "@material-ui/core";
import { FormErrors } from "../../../components/Login/FormErrors";
import { userService } from "_services/user.service";
import enMsg from "__helpers/locale/en/en";
import { apiPath } from "api";
import { generalAuthTokenHeader } from "__helpers/auth-header";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Slide,
  Tooltip,
  withStyles,
  Collapse,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import CircularProgress from "@material-ui/core/CircularProgress";

import { basePath, dpBaseRoutes } from "base-routes";
import { FormGroup, FormControl, InputLabel } from "@material-ui/core";
import { Link, NavLink } from "react-router-dom";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import ProviderSection from "views/FindProvider/index";
import MultipleFindProvider from "views/FindProvider/MultipleSection";
import DialogAction from "components/DialogActions/DialogAction";
import backArrow from "assets/img/icons/arrow-back.svg";
import notesArrow from "assets/img/icons/notes-arrow.svg";
import { formatDateToLocalFormat } from "__helpers/util";
import PatientForm from "views/dpViews/PatientDashboard/PatientForm";
import {
  PATIENT_LIST,
  MULTIPLE_PATIENT_LIST_CATEGORIES,
} from "__helpers/constants";
import Sidebar from "react-sidebar";
import PatientNotes from "views/dpViews/SinglePatientDetail/PatientNotes";
import { findProviderService } from "__helpers/util";
import Scrollbars from "react-custom-scrollbars";
import {
  SELECTED_PATIENT_LIST,
  EMAIL_REGEX,
  PRIORITY_LIST_OBJ,
  MULTIPLE_PATIENT_LIST,
  MAXMRJ_FACILITY,
  VENDOR_FACILITY,
  USER_INFO,
} from "__helpers/constants";
import DateFnsUtils from "@date-io/date-fns";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Downloader from "js-file-downloader";
import ConfirmModal from "components/Modal/modal";
import { downloadMedia } from "__helpers/util";
import moment from "moment-timezone";
import PatientTasks from "views/dpViews/SinglePatientDetail/PatientTasks";
import { TASK_STATUS_OBJ } from "__helpers/constants";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { daysBetweenTwoDate } from "__helpers/util";
import Calendar from "../Calendar";
import { localDateFormat } from "__helpers/util";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});
const mapStateToProps = (state) => ({
  patientStatusList: state.patientStatusList,
  insuranceList: state.insuranceList,
  categoryListRedux: state.categoryList,
});
moment.tz.setDefault("UTC");
// moment.tz.setDefault('America/Toronto');
const tooltipStyles = {
  tooltip: {
    width: "auto !important",
    // height: "36px",
    borderRadius: "18px",
    boxShadow: "0 20px 80px 0",
  },
};
const CustomTooltip = withStyles(tooltipStyles)(Tooltip);

class SinglePatientInfoClass extends React.Component {
  constructor(props) {
    super(props);
    let spinner = document.getElementById("loadingSpinner");
    let patientList = JSON.parse(sessionStorage.getItem(PATIENT_LIST));
    let selectedPatients = JSON.parse(
      sessionStorage.getItem(MULTIPLE_PATIENT_LIST)
    );
    let userInfo = JSON.parse(localStorage.getItem(USER_INFO));
    let dpFacilityCode =
      userInfo &&
      userInfo.dischargePlanner &&
      userInfo.dischargePlanner.facility &&
      userInfo.dischargePlanner.facility.facilityCode
        ? userInfo.dischargePlanner.facility.facilityCode
        : null;
    this.dateUtility = new DateFnsUtils();
    this.state = {
      loading: false,
      modalStatus: false,
      patientModalStatus: false,
      warningDialogStatus: false,
      total: 0,
      selectionCount: 0,
      spinner: spinner,
      newStatus: "",
      newComment: "",
      currentComment: "",
      isTermConditionsChecked: false,
      selectedStatusId: "",
      warning: false,
      formErrors: {
        newStatus: "",
        newComment: "",
        sendToEmail: "",
        isTermConditionsChecked: "",
      },
      confirmModalStatus: false,
      confirmModalMessage: "",
      newStatusValid: false,
      newCommentValid: true,
      sendToEmailValid: false,
      isTermConditionsCheckedValid: true,
      patientName: "unknown",
      selectedPatientId: "",
      patientCategoryList: [],
      patientCategoryItemList: [],
      patientItemList: patientList ? patientList : [],
      patientList: [],
      loadMore: true,
      patientCategories: [],
      reduxLoadFlag: false,
      patientTaskCount: 0,
      estimatedDischargeDate: "",
      actualEstMoment: null,
      patientDOB: "",
      patientCurrentStatus: "",
      patientInsurance: "",
      categoryList: this.props.categoryListRedux,
      sidebarOpen: true,
      selectCategoryIndex: "",
      selectCategoryId: "",
      patientCriteriaModalStatus: false,
      providerDetailModalStatus: false,
      providerDetail: [],
      patientCommonCategoryList: [],
      sendToEmail: "",
      noCommentAvailable: true,
      openTaskModal: false,
      patientTaskId: "",
      reloadData: false,
      reloadData: false,
      downloadingFiles: false,
      multiPatientMode: false,
      multiSelectedPatients: selectedPatients ? selectedPatients : [],
      selectedPatientKey: 0,
      patientNotesStatus: false,
      downloadSheets: [],
      filesKey: 0,
      dpFacilityCode: dpFacilityCode,
      facilityType: dpFacilityCode ? VENDOR_FACILITY : MAXMRJ_FACILITY,
      isPatientDetailHidden: false,
      isShowCalendar: false,
    };
    this.fetchPatient = this.fetchPatient.bind(this);
    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
    this.providerDetailModalOpen = this.providerDetailModalOpen.bind(this);
    this.updatePatient = this.updatePatient.bind(this);
    this.downloadFacesheet = this.downloadFacesheet.bind(this);
    this.loaderStatus = this.loaderStatus.bind(this);
    this.openTaskModal = this.openTaskModal.bind(this);
    this.fetchPatientReload = this.fetchPatientReload.bind(this);
    this.fetchCommonCategories = this.fetchCommonCategories.bind(this);
    this.prevPatient = this.prevPatient.bind(this);
    this.nextPatient = this.nextPatient.bind(this);
    this.patientNotesClose = this.patientNotesClose.bind(this);
    this.patientNotesOpen = this.patientNotesOpen.bind(this);
    this.downloadFiles = this.downloadFiles.bind(this);
    this.handlePatientAccordian = this.handlePatientAccordian.bind(this);
  }
  confirmModalOpen = (message, dataId) => {
    this.setState({
      selectedStatusId: dataId,
      confirmModalMessage: message,
      confirmModalStatus: true,
    });
  };
  confirmModalClose = () => {
    this.setState({
      confirmModalStatus: false,
    });
  };
  onSetSidebarOpen(open) {
    this.setState({ sidebarOpen: open });
  }
  componentDidMount() {
    if (!this.state.patientItemList.length) {
      window.location.replace(dpBaseRoutes.dashboard.path);
    }
    const url = new URLSearchParams(this.props.location.search);
    const selectCategoryId = url.get("key");
    const multiPatientMode = atob(atob(url.get("mode")));
    this.setState({
      multiPatientMode: multiPatientMode == "true" ? multiPatientMode : false,
    });
    if (multiPatientMode) {
      let patientId =
        this.state.multiSelectedPatients &&
        this.state.multiSelectedPatients.length
          ? this.state.multiSelectedPatients[0]
          : null;
      this.setState(
        {
          reloadData: true,
          selectedPatientKey: 0,
        },
        () => this.fetchPatient(patientId)
      );
    } else {
      this.setState(
        {
          reloadData: true,
        },
        () => this.fetchPatient()
      );
    }
    if (localStorage.getItem("task_id")) {
      this.openTaskModal(localStorage.getItem("task_id"));
      localStorage.removeItem("task_id");
    }
  }
  async fetchPatient(patientId) {
    try {
      if (!patientId) {
        patientId = JSON.parse(localStorage.getItem("_selected_patient_list"));
      }
      localStorage.setItem(SELECTED_PATIENT_LIST, JSON.stringify(patientId));
      let showNotification = {};
      let apiUrl = apiPath.getPatientList + "/" + patientId;
      this.setState({
        loading: true,
      });
      if (this.state.reloadData) {
        this.setState({
          patientList: [],
          patientCategoryList: [],
          reloadData: false,
        });
      }
      if (this.state.multiPatientMode) {
        this.setState({
          patientList: [],
          patientCategoryList: [],
          categoryList: [],
        });
      }
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: generalAuthTokenHeader(),
      })
        .then((response) => {
          if (response.status === 400) {
            showNotification = {
              title: "Patient Detail",
              message: "Bad response from server",
              type: "danger",
            };
          } else if (response.ok) {
          } else if (response.status === 401) {
            userService.logout();
            window.location.reload(true);
          } else {
            showNotification = {
              title: "Patient Detail",
              message: "Bad response from server",
              type: "danger",
            };
          }
          return response.json();
        })
        .then((data) => {
          if (data.length > 0) {
            let patientCategoryList = [];
            data[0].patientCatagoryMapping.map((el) => {
              return patientCategoryList.push(el.categoryId);
            });
            if (this.state.multiPatientMode) {
              this.fetchCommonCategories();
            }
            /**
             * START : update the patient list in local storage
             */
            let patientList = JSON.parse(sessionStorage.getItem(PATIENT_LIST));
            let localPatientList = [];
            localPatientList = patientList.map((pList, key) => {
              if (pList.patientId == data[0].patientId) {
                pList = data[0];
              }
              return pList;
            });
            sessionStorage.setItem(
              PATIENT_LIST,
              JSON.stringify(localPatientList)
            );
            /**
             * END : update the patient list in local storage
             */
            if (this.state.multiPatientMode) {
              this.setState({
                patientList: data[0],
                patientCategoryList: patientCategoryList,
                currentComment: data[0].comment,
                patientItemList: localPatientList,
                loading: false,
                categoryList: this.props.categoryListRedux,
                isShowCalendar: false,
              });
            } else {
              this.setState(
                {
                  patientList: data[0],
                  patientCategoryList: patientCategoryList,
                  currentComment: data[0].comment,
                  patientItemList: localPatientList,
                  loading: false,
                  categoryList: this.props.categoryListRedux,
                  isShowCalendar: false,
                },
                () => {
                  this.setPatientData();
                }
              );
            }
          }
          return true;
        })
        .catch((error) => {
          showNotification = {
            title: "Patient Detail",
            message: "Bad response from server",
            type: "danger",
          };
          this.setState({ loading: false });
          return response;
        });
      userService.showNotification(showNotification);
    } catch (error) {
      this.setState({ loading: true });
    }
  }
  fetchCommonCategories() {
    try {
      this.setState({ loading: true });
      let data = {
        ids: this.state.multiSelectedPatients,
      };
      let showNotification = {};
      let apiUrl = apiPath.multiSelectPatientCat;
      const response = fetch(apiUrl, {
        method: "POST",
        headers: generalAuthTokenHeader(),
        body: JSON.stringify(data),
        data: JSON.stringify(data),
      })
        .then((response) => {
          if (response.status === 400) {
            showNotification = {
              title: "Patient Detail",
              message: "Bad response from server",
              type: "danger",
            };
          } else if (response.ok) {
          } else if (response.status === 401) {
            userService.logout();
            window.location.reload(true);
          } else {
            showNotification = {
              title: "Patient Detail",
              message: "Bad response from server",
              type: "danger",
            };
          }
          this.setState({ loading: false });
          return response.json();
        })
        .then((data) => {
          if (data && data.length) {
            this.setState(
              {
                patientCommonCategoryList: data,
              },
              () => {
                this.setPatientData();
              }
            );
          } else {
            window.location.replace(dpBaseRoutes.dashboard.path);
          }
          return true;
        })
        .catch((error) => {
          this.setState({ loading: false });
          window.history.replaceState(
            {},
            "Login Page",
            dpBaseRoutes.dashboard.path
          );
          return response;
        });
      userService.showNotification(showNotification);
    } catch (error) {
      this.setState({ loading: false });
    }
  }
  setPatientData = () => {
    const url = new URLSearchParams(this.props.location.search);
    const selectCategoryId = url.get("key");
    this.setState({
      selectCategoryId: selectCategoryId,
    });
    let selectCategoryIndex = 0;
    let indexVal = 0;
    if (this.state.multiPatientMode) {
      this.state.categoryList
        .sort((a, b) => (a.catagoryId > b.catagoryId ? 1 : -1))
        .map((category, index) => {
          if (
            category.parent == 0 &&
            this.state.patientCommonCategoryList.includes(category.catagoryId)
          ) {
            if (category.catagoryId == selectCategoryId) {
              selectCategoryIndex = indexVal;
              return;
            }
            indexVal = indexVal + 1;
          }
        });
    } else {
      this.state.patientList.patientCatagoryMapping
        .sort((a, b) => (a.categoryId > b.categoryId ? 1 : -1))
        .map((category, index) => {
          if (category.categoryId == selectCategoryId) {
            selectCategoryIndex = index;
          }
        });
    }
    let estDate = null;
    this.setState({
      selectCategoryIndex: selectCategoryIndex,
    });
    if (this.state.patientList.formattedEstimatedDischargeDate) {
      estDate = this.dateUtility.format(
        new Date(this.state.patientList.formattedEstimatedDischargeDate),
        "MM/dd/yyyy"
      );
    }
    let localActualEstMoment = this.state.patientList.actualDischargeDate
      ? moment
          .utc(this.state.patientList.actualDischargeDate)
          .format("MM/DD/YYYY")
      : null;
    let dobDate = null;
    if (this.state.patientList.formattedPatientDob) {
      dobDate = this.dateUtility.format(
        new Date(this.state.patientList.formattedPatientDob),
        "MM/dd/yyyy"
      );
    }
    this.setState(
      {
        estimatedDischargeDate: estDate,
        actualEstMoment: localActualEstMoment,
        patientDOB: dobDate,
        patientInsurance: this.state.patientList.patientInsurance,
      },
      () => {
        this.checkEmptyComments();
      }
    );
    this.props.patientStatusList.map((status) => {
      if (status.patientStatusId == this.state.patientList.patientStatusId) {
        this.setState({
          patientCurrentStatus: status.patientStatusValue,
        });
      }
    });
    this.props.insuranceList.map((insurance) => {
      if (insurance.insuranceId == this.state.patientList.insuranceId) {
        this.setState({
          patientInsurance: insurance.insuranceName,
        });
      }
    });
  };
  componentDidUpdate() {}
  modalOpen = () => {
    this.setState(
      {
        modalStatus: true,
        newStatus: "",
        newComment: "",
      },
      () => {
        this.checkEmptyComments();
      }
    );
  };
  checkEmptyComments = () => {
    this.state.patientList.patientStatusHistory &&
      this.state.patientList.patientStatusHistory.map((status) => {
        if (status.comment) {
          this.setState({
            noCommentAvailable: false,
          });
          return true;
        }
      });
  };
  modalClose = () => {
    this.setState({
      modalStatus: false,
    });
  };
  warningDialogClose = () => {
    this.setState({
      warningDialogStatus: false,
    });
  };
  warningDialogOpen = () => {
    this.setState({
      warningDialogStatus: true,
    });
  };
  changeStatus = async (event) => {
    event.preventDefault();
    let showNotification = {};
    let DischargeDate = "";
    let inactiveDate = "";
    let currentDate = moment().format();
    var dischargeDaysDiff = 0;
    var inactiveDaysDiff = 0;
    if (this.state.patientList.actualDischargeDate) {
      DischargeDate = moment(this.state.patientList.actualDischargeDate);
      DischargeDate = moment("2020-08-09T06:00:00");
      const dischargedDateDiff = DischargeDate.diff(currentDate);
      const dischargedDateDiffDuration = moment.duration(dischargedDateDiff);
      dischargeDaysDiff = dischargedDateDiffDuration.days();
    }
    if (this.state.patientList.inactiveStatusDate) {
      inactiveDate = moment(this.state.patientList.inactiveStatusDate);
      inactiveDate = moment("2020-08-09T06:00:00");
      const inactiveDateDiff = inactiveDate.diff(currentDate);
      const inactiveDateDiffDuration = moment.duration(inactiveDateDiff);
      inactiveDaysDiff = inactiveDateDiffDuration.days();
    }
    const data = {
      comment: this.state.newComment ? this.state.newComment : "",
      patientId: this.state.patientList.patientId,
      patientStatsId: this.state.newStatus,
      // createdById: this.state.patientList.createdById,
    };
    this.setState({
      loading: true,
    });
    const response = await fetch(apiPath.changePatientStatus, {
      method: "PUT",
      headers: generalAuthTokenHeader(),
      body: JSON.stringify(data),
      data: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status === 400) {
          showNotification = {
            title: enMsg.updatePatientFailedTitle,
            message: "Bad response from server",
            type: "danger",
          };
        } else if (response.ok) {
        } else {
          showNotification = {
            title: enMsg.updatePatientFailedTitle,
            message: enMsg.updatePatientFailedDesc,
            type: "danger",
          };
          let error = new Error(response.statusText);
        }
        this.setState({ loading: false });
        return response.json();
      })
      .then((data) => {
        if (data.patientId) {
          showNotification = {
            title: "Success",
            message: "Patient status updated",
            type: "success",
          };
          this.props.patientStatusList.map((status) => {
            if (status.patientStatusId == data.patientStatsId) {
              this.setState({
                patientCurrentStatus: status.patientStatusValue,
              });
            }
          });
          this.fetchPatient(data.patientId);
          this.setState({
            currentComment: data.comment,
          });
          this.modalClose();
          return true;
        }
        if (data.title) {
          showNotification = {
            title: "Status",
            message: data.title,
            type: "warning",
          };
        }
      })
      .catch((error) => {
        showNotification = {
          title: enMsg.updatePatientFailedTitle,
          message: enMsg.updatePatientFailedDesc,
          type: "danger",
        };
      });
    userService.showNotification(showNotification);
    this.setState({ loading: false });
  };
  updatePatientModalOpen = () => {
    this.setState({
      patientModalStatus: true,
    });
  };
  updatePatientModalClose = () => {
    let patientList = JSON.parse(sessionStorage.getItem(PATIENT_LIST));
    this.setState({
      patientModalStatus: false,
      openTaskModal: false,
      patientItemList: patientList,
    });
  };
  patientCriteriaModalOpen = () => {
    this.setState({
      patientCriteriaModalStatus: true,
    });
  };
  patientCriteriaModalClose = () => {
    this.setState({
      patientCriteriaModalStatus: false,
    });
  };
  handleSendToEmailInput = (event) => {
    const name = event.target.name;
    let value = event.target.value;
    if (name == "isTermConditionsChecked") {
      value = event.target.checked;
    }
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.validateSendToEmailField(name, value);
      }
    );
  };
  validateSendToEmailField = (fieldName, fieldValue) => {
    let fieldValidationErrors = this.state.formErrors;
    let sendToEmailValid = this.state.sendToEmailValid;
    let isTermConditionsCheckedValid = this.state.isTermConditionsChecked;
    switch (fieldName) {
      case "sendToEmail":
        if (fieldValue.trim().length == 0) {
          sendToEmailValid = false;
          fieldValidationErrors.sendToEmail = enMsg.emailRequiredMsg;
        } else if (!fieldValue.trim().match(EMAIL_REGEX)) {
          sendToEmailValid = false;
          fieldValidationErrors.sendToEmail = "Invalid email";
        } else {
          sendToEmailValid = true;
          fieldValidationErrors.sendToEmail = "";
        }
        break;
      case "isTermConditionsChecked":
        isTermConditionsCheckedValid = fieldValue;
        fieldValidationErrors.isTermConditionsChecked = isTermConditionsCheckedValid
          ? ""
          : "Please check this checkbox.";
      default:
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        sendToEmailValid: sendToEmailValid,
        isTermConditionsCheckedValid: isTermConditionsCheckedValid,
      },
      this.validateSendProviderDetailForm
    );
  };
  validateSendProviderDetailForm() {
    return this.state.sendToEmailValid;
  }
  handleChangeInput = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    console.log(this.state.patientList);
    let actualEstMoment = this.state.actualEstMoment;
    let patientList = this.state.patientList;
    let actualDays = 0;
    if (actualEstMoment) {
      actualDays = daysBetweenTwoDate(actualEstMoment, new Date());
    }
    console.log(actualDays, value);
    if (name == "newStatus") {
      if (value == 6) {
        if (
          actualDays <= 30 ||
          (patientList.patientStatus &&
            patientList.patientStatus.patientStatusId !== 5)
        ) {
          this.setState({
            warning: true,
          });
        }
      } else {
        this.setState({
          warning: false,
        });
      }
    }
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.validateField(name, value);
      }
    );
  };
  validateField = (fieldName, fieldValue) => {
    let fieldValidationErrors = this.state.formErrors;
    let newCommentValid = this.state.newCommentValid;
    let newStatusValid = this.state.newStatusValid;

    switch (fieldName) {
      case "newComment":
        newCommentValid = !(fieldValue.trim().length > 255);
        fieldValidationErrors.newComment = newCommentValid
          ? ""
          : enMsg.commenLengthMsg;
        break;
      case "newStatus":
        newStatusValid = fieldValue > 0;
        fieldValidationErrors.newStatus = newStatusValid
          ? ""
          : enMsg.statusRequiredMsg;
        break;
      default:
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        newStatusValid: newStatusValid,
        newCommentValid: newCommentValid,
      },
      this.validateForm
    );
  };
  validateForm() {
    return this.state.newCommentValid && this.state.newStatusValid;
  }
  providerDetailModalOpen(providerId) {
    if (providerId) {
      this.setState({
        loading: true,
      });
      let apiUrl = "";
      apiUrl = apiPath.getProviders + "/" + providerId;
      const response = fetch(apiUrl, {
        method: "GET",
        headers: generalAuthTokenHeader(),
      })
        .then((response) => {
          if (response.status === 400) {
          } else if (response.ok) {
          } else if (response.status === 401) {
          } else {
          }
          this.setState({
            loading: false,
          });
          return response.json();
        })
        .then((data) => {
          this.setState({
            providerDetail: data,
          });
        })
        .catch((error) => {
          return response;
        });
      this.setState({
        providerDetailModalStatus: true,
      });
    }
  }
  providerDetailModalClose = () => {
    this.setState({
      providerDetailModalStatus: false,
    });
  };
  loaderStatus = (status) => {
    this.setState({
      loading: status,
    });
  };
  sendProviderDetail = (event) => {
    event.preventDefault();
    try {
      this.state.spinner.removeAttribute("hidden", "true");
      let showNotification = {};
      let apiUrl =
        apiPath.sendProviderDetail +
        "?id=" +
        this.state.providerDetail.id +
        "&mail=" +
        this.state.sendToEmail;
      this.setState({ loading: true });
      const response = fetch(apiUrl, {
        method: "GET",
        headers: generalAuthTokenHeader(),
      })
        .then((response) => {
          if (response.status === 400) {
            showNotification = {
              title: "Patient Detail",
              message: "Bad response from server",
              type: "danger",
            };
          } else if (response.ok) {
            showNotification = {
              title: "Provider Detail",
              message: "Email send successfully",
              type: "success",
            };
            userService.showNotification(showNotification);
          } else if (response.status === 401) {
          } else {
            showNotification = {
              title: "Patient Detail",
              message: "Bad response from server",
              type: "danger",
            };
          }
          return response.json();
        })
        .then((data) => {
          console.log(data);
          this.state.spinner.setAttribute("hidden", "true");
          this.setState({ loading: false });
          if (data.title) {
            showNotification = {
              title: "Send Mail",
              message: data.title,
              type: "danger",
            };
          }
          userService.showNotification(showNotification);
          return true;
        })
        .catch((error) => {
          showNotification = {
            title: "Patient Detail",
            message: "Bad response from server",
            type: "danger",
          };
          this.setState({ loading: false });
          this.state.spinner.setAttribute("hidden", "true");
          return response;
        });
      userService.showNotification(showNotification);
    } catch (error) {
      this.setState({ loading: true });
    }
    this.providerDetailModalClose();
  };
  downloadMedia(url) {}
  downloadFacesheet = async (urls) => {
    if (
      urls &&
      urls.length &&
      this.state.patientList &&
      this.state.patientList.patientId
    ) {
      this.setState({
        downloadingFiles: true,
      });
      let showNotification = {};
      if (this.state.filesKey == 0) {
        showNotification = {
          title: "Facesheet",
          message:
            "Downloading " +
            urls.length +
            " file(s). This process might take a long time, please be patient.",
          type: "infoLong",
        };
      }
      userService.showNotification(showNotification);
      // let sheetData = await userService.fetchSheetsData(apiPath.downloadFiles, this.state.patientList.patientId);
      // urls.map((fileData, keys) => {
      //     console.log(fileData);
      //     let urlArr = fileData.uploadUrl.split('/')
      //     let urlArrEndPoint = urlArr[urlArr.length - 2] + "/" + urlArr[urlArr.length - 1];
      //     let sheetData = userService.fetchCommentSheetsData(apiPath.downloadCommentFile + "?key=" + urlArrEndPoint, fileData.uploadName);
      //     if (sheetData) {
      //         this.setState({
      //             loading: false,
      //         })
      //     }
      // })

      let allFiles = false;
      // let downloadSheets = [];
      // urls.map( (fileData, keys) => {
      //     console.log(fileData);
      //     let urlArr = fileData.uploadUrl.split('/')
      //     let urlArrEndPoint = urlArr[urlArr.length - 2] + "/" + urlArr[urlArr.length - 1];
      //     let sheetData = userService.fetchFilesBlob(apiPath.downloadCommentFile + "?key=" + urlArrEndPoint, fileData.uploadName);
      //     console.log(fileObj);
      //     let fileObj = {};
      //     fileObj.name = fileData.uploadName
      //     fileObj.data = sheetData
      //     downloadSheets.push(fileObj);
      //     // if (keys + 1 == urls.length && downloadSheets.length == urls.length) {
      //     //     this.setState({
      //     //         loading: false,
      //     //         downloadSheets: downloadSheets,
      //     //     }, () => this.downloadFiles())
      //     // }
      // })
      // let timeWait = urls.length * 1000;
      // setTimeout(function () {
      //     this.setState({
      //         loading: false,
      //         downloadSheets: downloadSheets,
      //     }, () => this.downloadFiles())
      // }.bind(this), timeWait);

      let filesKey = this.state.filesKey;
      let downloadSheets = this.state.downloadSheets;
      let fileData = urls[filesKey];
      let urlArr = fileData.uploadUrl.split("/");
      let urlArrEndPoint =
        urlArr[urlArr.length - 2] + "/" + urlArr[urlArr.length - 1];
      let sheetData = await userService.fetchFilesBlob(
        apiPath.downloadCommentFile + "?key=" + urlArrEndPoint,
        fileData.uploadName
      );
      if (sheetData) {
        let fileObj = {};
        fileObj.name = fileData.uploadName;
        fileObj.data = sheetData;
        downloadSheets.push(fileObj);
        filesKey = filesKey + 1;
        if (filesKey == urls.length) {
          this.setState(
            {
              downloadSheets: downloadSheets,
              filesKey: 0,
              downloadingFiles: false,
            },
            () => this.downloadFiles()
          );
        } else {
          this.setState(
            {
              downloadSheets: downloadSheets,
              filesKey: filesKey,
            },
            () => this.downloadFacesheet(urls)
          );
        }
      } else {
        this.setState({
          downloadingFiles: false,
        });
      }
    } else {
      let showNotification = {
        title: "Facesheet",
        message: "No facesheet available.",
        type: "danger",
      };
      this.setState({
        downloadingFiles: false,
      });
      userService.showNotification(showNotification);
    }
    if (urls && urls.length && 1 == 2) {
      try {
        this.setState({
          downloadingFiles: true,
        });
        let showNotification = {
          title: "Facesheet",
          message: "Please wait, Downloading " + urls.length + " files",
          type: "success",
        };
        userService.showNotification(showNotification);
        urls.map((fileUrl, key) => {
          let index = key + 1;
          let fileName = "";
          fileName = fileUrl.uploadName;
          downloadMedia(fileUrl.uploadUrl, fileName);
        });
        setTimeout(() => {
          this.setState({
            downloadingFiles: false,
          });
        }, 2000);
      } catch (error) {
        let showNotification = {
          title: "Facesheet",
          message: "No facesheet available.",
          type: "danger",
        };
        userService.showNotification(showNotification);
        this.setState({
          downloadingFiles: false,
        });
      }
    } else {
    }
  };
  downloadFiles() {
    var zip = new JSZip();
    if (this.state.downloadSheets && this.state.downloadSheets.length) {
      this.state.downloadSheets.map((fileData, key) => {
        zip.file(key + "_" + fileData.name, fileData.data);
      });
    }
    zip.generateAsync({ type: "blob" }).then(function(content) {
      // see FileSaver.js
      let fileName = "pateintFiles_" + Date.now() + ".zip";
      saveAs(content, fileName);
    });
    this.setState({
      downloadSheets: [],
    });
    console.log(this.state.downloadSheets);
  }
  selectedProviderDataCallback(providerId) {}
  updatePatient() {
    this.fetchPatient();
  }
  fetchPatientReload(patientId) {
    const index = this.state.multiSelectedPatients.indexOf(patientId);
    if (index > -1) {
      this.setState({
        selectedPatientKey: index,
      });
    }
    if (patientId) {
      this.setState(
        {
          reloadData: true,
          isPatientDetailHidden: false,
        },
        () => this.fetchPatient(patientId)
      );
    } else {
      this.setState(
        {
          reloadData: true,
        },
        () => this.fetchPatient()
      );
    }
  }
  deleteStatus = () => {
    try {
      let showNotification = {};
      let apiUrl =
        apiPath.patientStatusDelete + "/" + this.state.selectedStatusId;
      this.setState({ loading: true });
      const response = fetch(apiUrl, {
        method: "DELETE",
        headers: generalAuthTokenHeader(),
      })
        .then((response) => {
          if (response.status === 400) {
            showNotification = {
              title: "Patient Status",
              message: "Bad response from server",
              type: "danger",
            };
          } else if (response.ok) {
            showNotification = {
              title: "Provider Status",
              message: "Comment deleted successfully",
              type: "success",
            };
            this.setState(
              {
                noCommentAvailable: true,
              },
              () => {
                this.fetchPatient();
              }
            );
            this.confirmModalClose();
          } else if (response.status === 401) {
          } else {
            showNotification = {
              title: "Patient Status",
              message: "Bad response from server",
              type: "danger",
            };
          }
          userService.showNotification(showNotification);
          return response.json();
        })
        .then((data) => {
          this.setState({ loading: false });
          return true;
        })
        .catch((error) => {
          showNotification = {
            title: "Patient Detail",
            message: "Bad response from server",
            type: "danger",
          };
          this.setState({ loading: false });
          return response;
        });
      userService.showNotification(showNotification);
    } catch (error) {
      this.setState({ loading: true });
    }
  };
  openTaskModal(taskId) {
    this.setState({
      openTaskModal: true,
      patientTaskId: taskId,
    });
  }
  prevPatient() {
    let selectedPatientKey = this.state.selectedPatientKey;
    selectedPatientKey =
      selectedPatientKey > 0
        ? selectedPatientKey - 1
        : this.state.multiSelectedPatients.length - 1;
    let patientId =
      this.state.multiSelectedPatients &&
      this.state.multiSelectedPatients.length
        ? this.state.multiSelectedPatients[selectedPatientKey]
        : null;
    this.setState(
      {
        selectedPatientKey: selectedPatientKey,
      },
      () => this.fetchPatient(patientId)
    );
  }
  nextPatient() {
    let selectedPatientKey = this.state.selectedPatientKey;
    selectedPatientKey =
      selectedPatientKey >= this.state.multiSelectedPatients.length - 1
        ? 0
        : selectedPatientKey + 1;
    let patientId =
      this.state.multiSelectedPatients &&
      this.state.multiSelectedPatients.length
        ? this.state.multiSelectedPatients[selectedPatientKey]
        : null;
    this.setState(
      {
        selectedPatientKey: selectedPatientKey,
      },
      () => this.fetchPatient(patientId)
    );
  }
  patientNotesClose() {}
  patientNotesOpen() {
    let notesStatus = this.state.patientNotesStatus ? false : true;
    this.setState({
      patientNotesStatus: notesStatus,
    });
  }
  handlePatientAccordian() {
    this.setState({
      isPatientDetailHidden: !this.state.isPatientDetailHidden,
    });
  }
  render() {
    const { facilityType, actualEstMoment, isPatientDetailHidden } = this.state;
    return (
      <div id="single-patient-info">
        <ConfirmModal
          title="Alert"
          message={this.state.confirmModalMessage}
          confirmModalStatus={this.state.confirmModalStatus}
          confirmModalClose={this.confirmModalClose}
          submit={this.deleteStatus}
          loading={this.state.loading}
        />
        <Dialog
          open={this.state.modalStatus}
          maxWidth={"sm"}
          onClose={this.modalClose}
          aria-labelledby="provider-dialog-title"
          className="add-modal front-modal PatientStatusDialog "
          TransitionComponent={Transition}
        >
          <DialogTitle className="add-modal-title" id="provider-dialog-title">
            Patient Status
            <IconButton
              className="closeButton"
              aria-label="close"
              onClick={this.modalClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <form onSubmit={this.changeStatus} noValidate>
            <DialogContent>
              <h3 className="patient-name">
                {this.state.patientList.lastName +
                  ", " +
                  this.state.patientList.firstName +
                  " " +
                  (this.state.patientList.middleName
                    ? this.state.patientList.middleName
                    : "")}
              </h3>
              <Box
                display="flex"
                justifyContent="flex-start"
                mb={2}
                pr={2}
                mt={1}
              >
                <Box pr={1} width={1}>
                  <TextField
                    name="currentStatus"
                    value={this.state.patientCurrentStatus}
                    disabled={true}
                    label="Current Status"
                  />
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                mb={2}
                pr={1}
              >
                <div className="notes-h">Notes</div>
                <Scrollbars
                  autoHide={false}
                  universal={true}
                  autoHeight={true}
                  autoHeightMin={0}
                  autoHeightMax={130}
                  className="YOnlytScrollbars"
                >
                  <Box pr={2} width={1} className="comments-list">
                    {this.state.patientList.patientStatusHistory &&
                    this.state.patientList.patientStatusHistory.length &&
                    !this.state.noCommentAvailable ? (
                      this.state.patientList.patientStatusHistory.map(
                        (statusHistory, key) =>
                          statusHistory.comment && (
                            <div key={key}>
                              <TextField
                                name="currentComment"
                                value={statusHistory.comment}
                                disabled={true}
                              />
                              <Button
                                className="btn2"
                                onClick={() =>
                                  this.confirmModalOpen(
                                    "Do you want to delete the comment?",
                                    statusHistory.id
                                  )
                                }
                              >
                                Delete
                              </Button>
                            </div>
                          )
                      )
                    ) : (
                      <p className="no-notes">No previous notes</p>
                    )}
                  </Box>
                </Scrollbars>
              </Box>
              <Box display="flex" justifyContent="flex-start" mb={0} pr={2}>
                <Box pr={1} width={1}>
                  <FormControl>
                    <InputLabel className={"required-label"}>
                      New Status
                    </InputLabel>
                    <Select
                      value={this.state.newStatus}
                      name="newStatus"
                      onChange={this.handleChangeInput}
                      displayEmpty
                    >
                      {this.props.patientStatusList.map((status, key) => (
                        <MenuItem key={key} value={status.patientStatusId}>
                          {status.patientStatusValue}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormErrors
                      show={!this.state.newStatusValid}
                      formErrors={this.state.formErrors}
                      fieldName="newStatus"
                    />
                  </FormControl>
                </Box>
              </Box>
              <Box display="flex" justifyContent="flex-start" mb={1} pr={2}>
                <Box pr={1} width={1}>
                  <TextField
                    name="newComment"
                    value={this.state.newComment}
                    onChange={this.handleChangeInput}
                    data-validators="isRequired"
                    label="New Note"
                    multiline={true}
                    rows={3}
                  />
                  <FormErrors
                    show={!this.state.newCommentValid}
                    formErrors={this.state.formErrors}
                    fieldName="newComment"
                  />
                </Box>
              </Box>
              <Box
                display={this.state.warning ? "flex" : "none"}
                justifyContent="flex-start"
                mb={2}
                pr={2}
                mt={1}
              >
                <Box pr={1} mt={1} width={1}>
                  <p className="warning-text">
                    <i
                      class="fa fa-exclamation-triangle"
                      aria-hidden="true"
                    ></i>{" "}
                    This patient has not been Discharged more then 30 days ago
                    or Inactive for over a week! New events may occur!
                  </p>
                </Box>
              </Box>
            </DialogContent>
            <DialogActions className="modal-actions" justify="center">
              <Button
                type="submit"
                className="btn1"
                disabled={!this.validateForm() || this.state.loading}
              >
                {this.state.loading && (
                  <CircularProgress size={24} className="buttonProgress" />
                )}
                Update Status
              </Button>
              <Button onClick={this.modalClose} className="cancel-link">
                Cancel
              </Button>
            </DialogActions>
          </form>
        </Dialog>
        <Dialog
          open={this.state.providerDetailModalStatus}
          maxWidth={"sm"}
          onClose={this.providerDetailModalClose}
          aria-labelledby="provider-dialog-title"
          className="add-modal front-modal ProviderInfo "
          TransitionComponent={Transition}
        >
          <DialogTitle className="add-modal-title" id="provider-dialog-title">
            <div className="providerName">
              {this.state.providerDetail.organizationEntity &&
              this.state.providerDetail.organizationEntity.length &&
              this.state.providerDetail.organizationEntity[0].orgName
                ? this.state.providerDetail.organizationEntity[0].orgName
                : "N/A"}
            </div>
            <IconButton
              className="closeButton"
              aria-label="close"
              onClick={this.providerDetailModalClose}
            >
              <CloseIcon />
            </IconButton>
            <div className="loaderProgressFixed">
              {this.state.loading ? <LinearProgress color="primary" /> : ""}
            </div>
          </DialogTitle>
          <Scrollbars
            autoHide={false}
            universal={true}
            autoHeight={true}
            autoHeightMin={0}
            autoHeightMax={600}
          >
            <form onSubmit={this.sendProviderDetail} noValidate>
              <DialogContent>
                <Box
                  width={1}
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-start"
                  mb={0}
                  mt={2}
                >
                  <Box
                    width={1}
                    className="provider-info-div"
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-start"
                  >
                    <div className="orgName">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                      >
                        <path
                          id="account_circle"
                          data-name="account circle"
                          d="M9,14.038a5.033,5.033,0,0,1-4.2-2.254C4.819,10.393,7.6,9.628,9,9.628s4.18.765,4.2,2.156A5.033,5.033,0,0,1,9,14.038ZM9,4.1A2.1,2.1,0,1,1,6.9,6.2,2.1,2.1,0,0,1,9,4.1ZM9,2a7,7,0,1,0,7,7A7,7,0,0,0,9,2Z"
                          transform="translate(-1.999 -1.998)"
                          fill="#01a7a6"
                        />
                      </svg>
                      <span className="phoneNumber">
                        {this.state.providerDetail.organizationEntity &&
                        this.state.providerDetail.organizationEntity.length &&
                        this.state.providerDetail.organizationEntity[0]
                          .primaryFirstName
                          ? this.state.providerDetail.organizationEntity[0]
                              .primaryFirstName +
                            " " +
                            this.state.providerDetail.organizationEntity[0]
                              .primaryLastName
                          : "N/A"}
                      </span>
                    </div>
                    <div className="phoneNumber">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14.609"
                        height="14.608"
                        viewBox="0 0 14.609 14.608"
                      >
                        <path
                          id="phone"
                          d="M5.94,9.324a12.228,12.228,0,0,0,5.347,5.344l1.785-1.788a.813.813,0,0,1,.825-.2,9.326,9.326,0,0,0,2.9.461.811.811,0,0,1,.812.812v2.84a.811.811,0,0,1-.812.812A13.8,13.8,0,0,1,3,3.81.811.811,0,0,1,3.81,3h2.84a.811.811,0,0,1,.812.812,9.326,9.326,0,0,0,.461,2.9.811.811,0,0,1-.2.824Z"
                          transform="translate(-2.999 -2.998)"
                          fill="#01a7a6"
                        />
                      </svg>
                      <span className="phoneNumber">
                        {this.state.providerDetail.organizationEntity &&
                        this.state.providerDetail.organizationEntity.length &&
                        this.state.providerDetail.organizationEntity[0].phone
                          ? "(" +
                            this.state.providerDetail.organizationEntity[0].phone.match(
                              /(\d{3})(\d{3})(\d{4})/
                            )[1] +
                            ") " +
                            this.state.providerDetail.organizationEntity[0].phone.match(
                              /(\d{3})(\d{3})(\d{4})/
                            )[2] +
                            "-" +
                            this.state.providerDetail.organizationEntity[0].phone.match(
                              /(\d{3})(\d{3})(\d{4})/
                            )[3]
                          : "N/A"}
                      </span>
                    </div>
                    <div className="email">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                      >
                        <path
                          id="web"
                          d="M12.052,10.4a11.264,11.264,0,0,0,.1-1.4,11.264,11.264,0,0,0-.1-1.4h2.363a5.415,5.415,0,0,1,0,2.8m-3.6,3.893A11.1,11.1,0,0,0,11.78,11.8h2.064A5.615,5.615,0,0,1,10.815,14.291ZM10.637,10.4H7.362A9.718,9.718,0,0,1,7.25,9a9.719,9.719,0,0,1,.112-1.4h3.275A9.719,9.719,0,0,1,10.75,9,9.718,9.718,0,0,1,10.637,10.4ZM9,14.573A9.771,9.771,0,0,1,7.662,11.8h2.675A9.758,9.758,0,0,1,9,14.573ZM6.218,6.2H4.154A5.6,5.6,0,0,1,7.183,3.7,11.079,11.079,0,0,0,6.218,6.2ZM4.154,11.8H6.218a11.092,11.092,0,0,0,.965,2.494A5.6,5.6,0,0,1,4.154,11.8Zm-.571-1.4a5.455,5.455,0,0,1,0-2.8H5.946A11.263,11.263,0,0,0,5.85,9a11.262,11.262,0,0,0,.1,1.4M9,3.423A9.758,9.758,0,0,1,10.337,6.2H7.662A9.772,9.772,0,0,1,9,3.423ZM13.844,6.2H11.78a11.1,11.1,0,0,0-.965-2.493A5.615,5.615,0,0,1,13.844,6.2ZM9,2a7,7,0,1,0,7,7A6.995,6.995,0,0,0,9,2Z"
                          transform="translate(-2 -1.998)"
                          fill="#01a7a6"
                        />
                      </svg>
                      {this.state.providerDetail.user &&
                      this.state.providerDetail.user.forEmailNotify
                        ? this.state.providerDetail.user.forEmailNotify
                        : "N/A"}
                    </div>
                    <div className="aboutProvider">
                      <Scrollbars
                        autoHide={false}
                        universal={true}
                        autoHeight={true}
                        autoHeightMin={0}
                        autoHeightMax={100}
                      >
                        {this.state.providerDetail.organizationEntity &&
                        this.state.providerDetail.organizationEntity.length &&
                        this.state.providerDetail.organizationEntity[0]
                          .providerAboutUs ? (
                          <div
                            style={{ whiteSpace: "pre-line" }}
                            ng-bind-html="demo.body|lineBreak"
                            dangerouslySetInnerHTML={{
                              __html: this.state.providerDetail
                                .organizationEntity[0].providerAboutUs,
                            }}
                          />
                        ) : null}
                      </Scrollbars>
                    </div>
                    <div className="address mt-20">
                      {this.state.providerDetail.organizationEntity &&
                      this.state.providerDetail.organizationEntity.length &&
                      this.state.providerDetail.organizationEntity[0].state ? (
                        <div>
                          <p>
                            {this.state.providerDetail.organizationEntity &&
                            this.state.providerDetail.organizationEntity
                              .length &&
                            this.state.providerDetail.organizationEntity[0]
                              .addressStreet
                              ? this.state.providerDetail.organizationEntity[0]
                                  .addressStreet
                              : null}
                          </p>
                          <p>
                            {this.state.providerDetail.organizationEntity &&
                            this.state.providerDetail.organizationEntity
                              .length &&
                            this.state.providerDetail.organizationEntity[0]
                              .addressSuite
                              ? this.state.providerDetail.organizationEntity[0]
                                  .addressSuite
                              : null}
                          </p>
                          <p>
                            {this.state.providerDetail.organizationEntity &&
                            this.state.providerDetail.organizationEntity
                              .length &&
                            this.state.providerDetail.organizationEntity[0].city
                              ? this.state.providerDetail.organizationEntity[0]
                                  .city
                              : null}
                          </p>
                          <p>
                            {this.state.providerDetail.organizationEntity &&
                            this.state.providerDetail.organizationEntity
                              .length &&
                            this.state.providerDetail.organizationEntity[0]
                              .websiteUrl ? (
                              <a
                                href={
                                  this.state.providerDetail.organizationEntity[0].websiteUrl.startsWith(
                                    "http"
                                  )
                                    ? this.state.providerDetail
                                        .organizationEntity[0].websiteUrl
                                    : "https://" +
                                      this.state.providerDetail
                                        .organizationEntity[0].websiteUrl
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {
                                  this.state.providerDetail
                                    .organizationEntity[0].websiteUrl
                                }
                              </a>
                            ) : null}
                          </p>
                        </div>
                      ) : null}
                    </div>
                  </Box>

                  <Box width={1} mt={3}>
                    <Box
                      width={1}
                      display="flex"
                      flexDirection="row"
                      justifyContent="flex-start"
                    >
                      <Box pr={1} width={"calc(100% - 72px)"}>
                        <TextField
                          name="sendToEmail"
                          value={this.state.sendToEmail}
                          onChange={this.handleSendToEmailInput}
                          data-validators="isRequired"
                          label="Email Details"
                        />
                        <FormErrors
                          show={!this.state.sendToEmailValid}
                          formErrors={this.state.formErrors}
                          fieldName="sendToEmail"
                        />
                      </Box>
                      <Box width={"72px"} className="submitbtn1">
                        <Button
                          type="submit"
                          className="btn1"
                          disabled={
                            !this.validateSendProviderDetailForm() ||
                            this.state.loading
                          }
                        >
                          {this.state.loading && (
                            <CircularProgress
                              size={24}
                              className="buttonProgress"
                            />
                          )}
                          Send
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </DialogContent>
              <DialogActions
                className="modal-actions"
                display="flex"
                width={1}
                justifyContent="center"
              >
                <Button
                  onClick={this.providerDetailModalClose}
                  className="btn1"
                >
                  Back to providers
                </Button>
              </DialogActions>
            </form>
          </Scrollbars>
        </Dialog>
        <PatientForm
          patientModalStatus={this.state.patientModalStatus}
          updatePatientModalClose={this.updatePatientModalClose}
          patientCategoryList={this.state.patientCategoryList}
          patientDetail={this.state.patientList}
          fetchPatient={this.fetchPatientReload}
        />
        <PatientTasks
          openTaskModal={this.state.openTaskModal}
          updatePatientModalClose={this.updatePatientModalClose}
          patientCategoryList={this.state.patientCategoryList}
          patientDetail={this.state.patientList}
          updatePatient={this.updatePatient}
          loaderStatus={this.loaderStatus}
          patientTaskId={this.state.patientTaskId}
        />

        <div className="loaderProgressFixed">
          {this.state.loading ? <LinearProgress color="primary" /> : ""}
        </div>
        <div className="ProviderFind">
          <Link
            // to={`${basePath}${dpBaseRoutes.patientList.path}`}
            to={{
              pathname: `${basePath}${dpBaseRoutes.patientList.path}`,
              state: {
                resetDashboard: false,
              },
            }}
            color="textSecondary"
            className="link-a"
            underline="none"
          >
            <h2 className="titlename">
              <img src={backArrow} /> {dpBaseRoutes.singlePatientInfo.pathName}
            </h2>
          </Link>
          <Box
            display="flex"
            flexDirection="row"
            width={1}
            mt={1.5}
            className="provider-find-page siglePatientPageMain"
          >
            <Box className="left-list">
              <div className="left-title-info">
                {this.state.multiPatientMode
                  ? this.state.multiSelectedPatients.length +
                    " Patient Selected"
                  : "1 Patient Selected"}
              </div>
              <Scrollbars
                className="Scrollbars-patient-info-cover"
                style={{ height: "calc(100vh - 184px)" }}
                universal={true}
                autoHide={false}
              >
                <Box
                  width={1}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  className="patient-info-cover"
                >
                  {typeof this.state.patientItemList === "object" &&
                  this.state.patientItemList.length
                    ? this.state.multiPatientMode
                      ? this.state.patientItemList
                          .sort((a, b) => (a.patientId > b.patientId ? -1 : 1))
                          .map((pList, key) => {
                            let eastimatedDischargeDate = null;
                            let patientDob = null;
                            let localActualEstMoment = pList.actualDischargeDate
                              ? moment
                                  .utc(pList.actualDischargeDate)
                                  .format("MM/DD/YYYY")
                              : null;
                            let patientDischargedWarning =
                              localActualEstMoment &&
                              daysBetweenTwoDate(
                                localActualEstMoment,
                                new Date()
                              ) >= 30
                                ? true
                                : false;
                            eastimatedDischargeDate = pList.eastimatedDischargeDate
                              ? moment
                                  .utc(
                                    pList.eastimatedDischargeDate,
                                    "YYYY-MM-DD"
                                  )
                                  .format("MM/DD/YYYY")
                              : "N/A";
                            if (pList.patientDob) {
                              patientDob = moment
                                .utc(pList.patientDob)
                                .format("MM/DD/YYYY");
                            }
                            if (
                              this.state.multiSelectedPatients.includes(
                                pList.patientId
                              )
                            ) {
                              return (
                                <Box
                                  key={key}
                                  className={`patient-info list-item-content ${
                                    this.state.patientList.patientId ==
                                    pList.patientId
                                      ? "selectedlist"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    this.fetchPatientReload(pList.patientId)
                                  }
                                >
                                  <div
                                    className={`${
                                      pList.notificationCount !== 0
                                        ? "task-count toshift"
                                        : "task-count"
                                    }`}
                                  >
                                    {pList.patientTask &&
                                    pList.patientTask.length
                                      ? pList.patientTask.length
                                      : ""}
                                  </div>
                                  <div className="notification-count">
                                    {pList.notificationCount
                                      ? pList.notificationCount
                                      : ""}
                                  </div>
                                  <div className="name">
                                    {pList.lastName}, {pList.firstName}{" "}
                                    {pList.middleName}
                                  </div>
                                  <div className="DC">DOB: {patientDob} </div>
                                  <div className="DC warning-sign">
                                    DC:{" "}
                                    {localActualEstMoment
                                      ? localActualEstMoment
                                      : eastimatedDischargeDate}
                                    {patientDischargedWarning ? (
                                      <CustomTooltip title="This patient is discharged more than 30 days ago.">
                                        <WarningIcon />
                                      </CustomTooltip>
                                    ) : null}
                                  </div>
                                  <div
                                    className={`status ${
                                      pList.patientStatus &&
                                      pList.patientStatus.patientStatusValue
                                        ? pList.patientStatus.patientStatusValue
                                        : "noStatus"
                                    }`}
                                  >
                                    {pList.patientStatus.patientStatusValue}
                                  </div>
                                </Box>
                              );
                            }
                          })
                      : this.state.patientItemList.map((pList, key) => {
                          let eastimatedDischargeDate = null;
                          let patientDob = null;
                          let localActualEstMoment = pList.actualDischargeDate
                            ? moment
                                .utc(pList.actualDischargeDate)
                                .format("MM/DD/YYYY")
                            : null;
                          let patientDischargedWarning =
                            localActualEstMoment &&
                            daysBetweenTwoDate(
                              localActualEstMoment,
                              new Date()
                            ) >= 30
                              ? true
                              : false;
                          eastimatedDischargeDate = pList.eastimatedDischargeDate
                            ? moment
                                .utc(
                                  pList.eastimatedDischargeDate,
                                  "YYYY-MM-DD"
                                )
                                .format("MM/DD/YYYY")
                            : "N/A";
                          if (pList.patientDob) {
                            patientDob = moment
                              .utc(pList.patientDob)
                              .format("MM/DD/YYYY");
                          }
                          return (
                            <Box
                              key={key}
                              className={`patient-info list-item-content ${
                                this.state.patientList.patientId ==
                                pList.patientId
                                  ? "selectedlist"
                                  : ""
                              }`}
                              onClick={() =>
                                this.fetchPatientReload(pList.patientId)
                              }
                            >
                              <div
                                className={`${
                                  pList.notificationCount !== 0
                                    ? "task-count toshift"
                                    : "task-count"
                                }`}
                              >
                                {pList.patientTask && pList.patientTask.length
                                  ? pList.patientTask.length
                                  : ""}
                              </div>
                              <div className="notification-count">
                                {pList.notificationCount
                                  ? pList.notificationCount
                                  : ""}
                              </div>
                              <div className="name">
                                {pList.lastName}, {pList.firstName}{" "}
                                {pList.middleName}
                              </div>
                              <div className="DC">DOB: {patientDob}</div>
                              <div className="DC warning-sign">
                                DC:{" "}
                                {localActualEstMoment
                                  ? localActualEstMoment
                                  : eastimatedDischargeDate}
                                {patientDischargedWarning ? (
                                  <CustomTooltip title="This patient is discharged more than 30 days ago.">
                                    <WarningIcon />
                                  </CustomTooltip>
                                ) : null}
                              </div>
                              <div
                                className={`status ${
                                  pList.patientStatus &&
                                  pList.patientStatus.patientStatusValue
                                    ? pList.patientStatus.patientStatusValue
                                    : "noStatus"
                                }`}
                              >
                                {pList.patientStatus.patientStatusValue}
                              </div>
                            </Box>
                          );
                        })
                    : "Patient Not Found"}
                </Box>
              </Scrollbars>
            </Box>
            <Box className="right-details-tabpanel rightTabPanelMain">
              <div
                className={`details-section ${
                  isPatientDetailHidden ? "hideshowDetail" : ""
                }`}
              >
                <div className="multiple-selection hideshhow">
                  {this.state.multiPatientMode ? (
                    <i
                      className="fa fa-chevron-left pointer"
                      onClick={() => this.prevPatient()}
                    ></i>
                  ) : null}
                  <div
                    className="CollapseBtn"
                    onClick={this.handlePatientAccordian}
                  >
                    {isPatientDetailHidden ? (
                      <span>
                        Show <ExpandMoreIcon />
                      </span>
                    ) : (
                      <span>
                        Hide <ExpandLessIcon />
                      </span>
                    )}
                  </div>
                  <div className="name-status-tag ">
                    {this.state.patientList.firstName ? (
                      <div className="name">
                        {this.state.patientList.lastName},{" "}
                        {this.state.patientList.firstName}{" "}
                        {this.state.patientList.middleName}
                      </div>
                    ) : (
                      <div className="name">loading...</div>
                    )}
                    <div
                      className={`status ${
                        this.state.patientList.patientStatus &&
                        this.state.patientList.patientStatus.patientStatusValue
                          ? this.state.patientList.patientStatus
                              .patientStatusValue
                          : "noStatus"
                      }`}
                    >
                      {this.state.patientCurrentStatus}
                    </div>
                  </div>
                  {this.state.multiPatientMode ? (
                    <i
                      className="fa fa-chevron-right pointer"
                      onClick={() => this.nextPatient()}
                    ></i>
                  ) : null}
                </div>
                <Collapse
                  className="PatientDetailCollapse"
                  in={!isPatientDetailHidden}
                  collapsedSize={40}
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    width={1}
                    mt={2}
                    mb={1}
                    className={"details-all-cover"}
                  >
                    <Box
                      width="32%"
                      className={"section details-cover"}
                      display="flex"
                      flexDirection="column"
                    >
                      <div className="section-heading"> DETAILS </div>
                      <Box
                        display="flex"
                        flexDirection="row"
                        width={1}
                        mt={1}
                        className="details-info"
                      >
                        <Box pr={2}>
                          <div className="info-label">
                            {actualEstMoment
                              ? "Discharged"
                              : "Expected Discharge"}
                          </div>
                          <div className="info-value">
                            {actualEstMoment
                              ? actualEstMoment
                              : this.state.estimatedDischargeDate
                              ? this.state.estimatedDischargeDate
                              : "N/A"}
                          </div>

                          <div className="info-label">Insurance</div>
                          <div className="info-value">
                            {this.state.patientInsurance
                              ? this.state.patientInsurance
                              : "None"}
                          </div>
                        </Box>

                        <Box pl={2}>
                          <div className="info-label">Date of birth</div>
                          <div className="info-value">
                            {this.state.patientDOB}
                          </div>

                          <div className="info-label">Gender</div>
                          <div className="info-value">
                            {this.state.patientList.gender}
                          </div>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      width="200px"
                      className={"section btns-cover"}
                      display="flex"
                      flexDirection="column"
                    >
                      <Button
                        className="pointer btn1"
                        onClick={this.updatePatientModalOpen}
                      >
                        Edit Patient
                      </Button>
                      <Button
                        className="pointer btn1"
                        onClick={() => {
                          this.setState((prevState) => {
                            return {
                              isShowCalendar: !prevState.isShowCalendar,
                              isPatientDetailHidden: !prevState.isShowCalendar,
                            };
                          });
                        }}
                      >
                        {this.state.isShowCalendar
                          ? "Care Category"
                          : "Calendar"}
                      </Button>
                      <Button className="pointer btn1" onClick={this.modalOpen}>
                        Change Status
                      </Button>
                      {this.state.patientList.patientUploadHistory ? (
                        <Button
                          className="pointer btn1"
                          disabled={this.state.downloadingFiles ? true : false}
                          onClick={() =>
                            this.downloadFacesheet(
                              this.state.patientList.patientUploadHistory
                            )
                          }
                        >
                          Download Face Sheet
                          {this.state.downloadingFiles ? (
                            <div class="download_loader">
                              <div class="preloader">
                                <span class="loader_span"></span>
                                <span class="loader_span"></span>
                                <span class="loader_span"></span>
                                <span class="loader_span"></span>
                                <span class="loader_span"></span>
                                <span class="loader_span"></span>
                                <span class="loader_span"></span>
                                <span class="loader_span"></span>
                                <span class="loader_span"></span>
                                <span class="loader_span"></span>
                                <span class="loader_span"></span>
                                <span class="loader_span"></span>
                                <span class="loader_span"></span>
                                <span class="loader_span"></span>
                                <span class="loader_span"></span>
                                <span class="loader_span"></span>
                                <span class="loader_span"></span>
                                <span class="loader_span"></span>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </Button>
                      ) : null}
                    </Box>
                    <Box
                      className={"section tasks-cover-all"}
                      display="flex"
                      flexDirection="column"
                    >
                      <Box
                        width={1}
                        className={"section tasks-cover"}
                        display="flex"
                        flexDirection="row"
                      >
                        <div className="section-heading  w-50"> Tasks </div>
                        <div className="addbtn-cover w-50">
                          <Button
                            className="pointer btn1 addbtn"
                            onClick={(e) => this.openTaskModal("")}
                            disabled={this.state.loading}
                          >
                            + Add New
                          </Button>
                        </div>
                      </Box>
                      <Scrollbars
                        autoHide={false}
                        universal={true}
                        autoHeight={true}
                        autoHeightMin={107}
                        autoHeightMax={107}
                        className="AllTaskListScroll"
                      >
                        <Box
                          width={1}
                          className={"section tasks-cover"}
                          display="flex"
                        >
                          {this.state.patientList &&
                          this.state.patientList.patientTask &&
                          this.state.patientList.patientTask.length ? (
                            this.state.patientList.patientTask
                              // .sort((a, b) => a.taskPriority > b.taskPriority ? -1 : 1)
                              .map((tList, key) => {
                                return (
                                  <div
                                    key={key}
                                    className={`task-name ${
                                      TASK_STATUS_OBJ[tList.changeStatus]
                                    }`}
                                  >
                                    <span
                                      onClick={(e) =>
                                        this.openTaskModal(tList.id)
                                      }
                                    >
                                      {tList.taskName}
                                      {tList.dueDate
                                        ? ` - ${localDateFormat(tList.dueDate)}`
                                        : ""}
                                    </span>
                                  </div>
                                );
                              })
                          ) : (
                            <div className="no-tasks">No Tasks!</div>
                          )}
                        </Box>
                      </Scrollbars>
                    </Box>
                    <Box
                      style={{ display: "none" }}
                      width="36%"
                      className={"section tasks-cover"}
                      display="flex"
                      flexDirection="column"
                    >
                      {this.state.patientList &&
                      this.state.patientList.patientTask &&
                      this.state.patientList.patientTask.length ? (
                        this.state.patientList.patientTask
                          // .sort((a, b) => a.changeStatus > b.changeStatus ? -1 : 1)
                          .map((tList, key) => {
                            return (
                              <div
                                key={key}
                                className={`task-name ${
                                  TASK_STATUS_OBJ[tList.changeStatus]
                                }`}
                                onClick={(e) => this.openTaskModal(tList.id)}
                              >
                                <span>{tList.taskName}</span>
                              </div>
                            );
                          })
                      ) : (
                        <div className="no-tasks">No Tasks!</div>
                      )}
                    </Box>
                    <Box style={{ display: "none" }}>
                      <Box
                        width="18%"
                        className={"section tasks-cover"}
                        display="flex"
                        flexDirection="column"
                      >
                        <div className="task-name clr1">
                          <span>Expected</span>
                        </div>
                        <div className="task-name clr1">
                          <span>Discharge</span>
                        </div>
                        <div className="task-name clr1">
                          <span>Insurance</span>
                        </div>
                        <div className="task-name clr2">
                          <span>Medinacre</span>
                        </div>
                      </Box>
                      <Box
                        width="18%"
                        className={"section add-cover"}
                        display="flex"
                        flexDirection="column"
                      >
                        <div className="task-name clr2">
                          <span>Expected</span>
                        </div>
                        <div className="task-name clr2">
                          <span>Discharge</span>
                        </div>
                        <div className="task-name clr2">
                          <span>Insurance</span>
                        </div>
                        <div className="task-name clr3">
                          <span>Medinacre</span>
                        </div>
                      </Box>
                    </Box>
                  </Box>
                </Collapse>
              </div>
              {this.state.isShowCalendar ? (
                <div>
                  <Calendar
                    isPatientSearch={false}
                    patientIdArray={[this.state.patientList.patientId]}
                    openTaskModal={this.openTaskModal}
                    {...this.props}
                  />
                </div>
              ) : (
                <div className="ProviderSection">
                  {this.state.multiPatientMode ? (
                    this.state.selectCategoryIndex !== "" &&
                    this.state.patientCommonCategoryList.length ? (
                      <MultipleFindProvider
                        categoryList={this.state.categoryList}
                        patientCategoryListArr={this.state.patientCategoryList}
                        patientCommonCategoryList={
                          this.state.patientCommonCategoryList
                        }
                        patientInfo={this.state.patientList}
                        selectCategoryIndex={this.state.selectCategoryIndex}
                        loading={this.state.loading}
                        providerDetailModal={this.providerDetailModalOpen}
                        selectedProviderDataCallback={
                          this.providerDetailModalOpen
                        }
                        updatePatient={this.updatePatient}
                        loaderStatus={this.loaderStatus}
                      />
                    ) : (
                      "Loading tabs.."
                    )
                  ) : this.state.selectCategoryIndex !== "" ? (
                    <ProviderSection
                      patientCategoryList={this.state.categoryList}
                      patientCategoryListArr={this.state.patientCategoryList}
                      patientInfo={this.state.patientList}
                      selectCategoryIndex={this.state.selectCategoryIndex}
                      loading={this.state.loading}
                      providerDetailModal={this.providerDetailModalOpen}
                      selectedProviderDataCallback={
                        this.providerDetailModalOpen
                      }
                      updatePatient={this.updatePatient}
                      loaderStatus={this.loaderStatus}
                    />
                  ) : (
                    "Loading tabs.."
                  )}
                </div>
              )}
            </Box>
          </Box>
        </div>
      </div>
    );
  }
}
export const SinglePatientInfo = connect(mapStateToProps)(
  SinglePatientInfoClass
);
export default SinglePatientInfo;
